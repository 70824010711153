import DataTable from "../../components/HigherOrderComponent/DataTable/DataTable";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Button } from "react-bootstrap";

const Watchlist = () => {
  const data = [
    {
      symbol: "ABSA",
      exchange: "NSE",
      ccy: "KES",
      pre_close: "27.20",
      last_price: "28.25",
      bid: "35.50",
      ask: "28.25",
    },
    {
      symbol: "ABSA",
      exchange: "NSE",
      ccy: "KES",
      pre_close: "27.20",
      last_price: "239.00",
      bid: "35.50",
      ask: "-",
    },
    {
        symbol: "ABSA",
        exchange: "NSE",
        ccy: "KES",
        pre_close: "27.20",
        last_price: "36.00",
        bid: "35.50",
        ask: "-",
      },
      {
        symbol: "ABSA",
        exchange: "NSE",
        ccy: "KES",
        pre_close: "27.20",
        last_price: "15.85",
        bid: "35.50",
        ask: "70.00",
      },
      {
        symbol: "ABSA",
        exchange: "NSE",
        ccy: "KES",
        pre_close: "27.20",
        last_price: "0.11",
        bid: "35.50",
        ask: "70.00",
      },
      {
        symbol: "ABSA",
        exchange: "NSE",
        ccy: "KES",
        pre_close: "27.20",
        last_price: "0.09",
        bid: "35.50",
        ask: "36.00",
      },
      {
        symbol: "ABSA",
        exchange: "NSE",
        ccy: "KES",
        pre_close: "27.20",
        last_price: "28.25",
        bid: "35.50",
        ask: "-",
      },
      {
        symbol: "ABSA",
        exchange: "NSE",
        ccy: "KES",
        pre_close: "27.20",
        last_price: "28.25",
        bid: "35.50",
        ask: "-",
      },
       
      

  ];
  const columnData = [
    {
      Header: "Symbol",
      accessor: "symbol",
      width: 50,
    },
    {
      Header: "Exchange",
      accessor: "exchange",
      width: 60,
    },
    {
      Header: "CCY",
      accessor: "ccy",
      width: 35,
    },
    {
      Header: "Px Prev",
      accessor: "pre_close",
      width: 50,
    },
    {
      Header: "Px Last",
      accessor: "last_price",
      width: 50,
      Cell: ({ cell: { value } }) => (
        <span className= {value == '28.25' ? 'text-success' : null || (value == '36.00' || value == '15.85') ? 'text-danger' : null }>{value}</span>
      ),
    },
    {
      Header: "Bid",
      accessor: "bid",
      width: 45,
    },
    {
      Header: "Ask",
      accessor: "ask",
      width:60,
      Cell: ({ cell: { value } }) => (
        <span className= {value == '70.00' && 'text-success' }>{value} {value == '70.00' ? <i className="fa fa-arrow-up"> </i> :''}</span>
      ),
    },
  ];

  return (
    <div className="panel panel-default h-100">
      <div className="panel-body paneldecoration">
        <div className="form-row ">
          <div className="col-md-9">
            <DropdownButton
              id="dropdown-basic-button"
              title="Seach..."
              variant="default"
              className="mb-3 mb-lg-0 mb-md-0 w-100"
            >
              <Dropdown.Item eventKey="option-1">Action</Dropdown.Item>
              <Dropdown.Item eventKey="option-2">Another action</Dropdown.Item>
              <Dropdown.Item eventKey="option-3">Something else</Dropdown.Item>
            </DropdownButton>
          </div>
          <div className="col-md-3 watchlist-btn d-flex align-items-center justify-content-end">
            <Button className="mr-2" size="sm" variant="success" type="submit">
              <i className="fa fa-plus"></i>
            </Button>
            <Button  size="sm" variant="danger" type="submit">
              <i className="fa fa-trash"></i>
            </Button>
          </div>
          
          <div className="col-md-12 mt-2">
            <DataTable pagination={false} columnData={columnData} data={data} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Watchlist;
