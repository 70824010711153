import React, { useState, useEffect } from 'react'
import { Row, Col, Card, Button, Form } from 'react-bootstrap'
import Select from 'react-select'
import { useLocation } from 'react-router';
export default function ProfileForm() {


    // this example data for now
    const options = [
        { value: 'option 1', label: 'Option 1' },
        { value: 'option 2', label: 'Option 2' },
        { value: 'option 3', label: 'Option 3' }
    ];

    const profile = {
        username: "John21",
        first_name: "John",
        last_name: "Harrison",
        role: "Admin",
        region: "Dublin",
    };

    //check if view
    const [viewOnly, setViewOnly] = useState(false);
    const query = new URLSearchParams(useLocation().search);

    useEffect(() => {
        setViewOnly(query.get("view") === "1")
    }, [])

    const form_1 = [
        { name: "username", label: "Username" ,type:"text"},
        { name: "first_name", label: "First Name",type:"text" },
        { name: "last_name", label: "Last Name",type:"text" },
        { name: "role", label: "Role" ,type:"text"},
        { name: "region", label: "Region" ,type:"text"},
    ];
    const form_2 = [
        { name: "fund", label: "Funds", isMulti: true ,type:"select2" , },
        { name: "strategy", label: "Strategy", isMulti: true ,type:"select2" },
        { name: "sub-strategy", label: "Sub-Strategy", isMulti: true ,type:"select2" },
        { name: "group", label: "Group", isMulti: true ,type:"select2" },
        { name: "assignment", label: "Assignment", isMulti: true ,type:"select2" }
    ];


    return (
        <div className="px-3">
            <nav aria-label="breadcrumb" className="mt-4">
                <ol className="breadcrumb text-light">
                    <li className="breadcrumb-item ">Admin</li>
                    <li className="breadcrumb-item">Profile Details</li>
                </ol>
            </nav>
            <Row className="profile-form-section">
                <Col md={4}>
                    {/* Profile Picture */}
                    <Card className="profile-form-photo-card mr-4">
                        <img src="https://lorempixel.com/1200/1200/" alt="picture" />
                        {
                            !viewOnly && (<Card.Body className="p-0">
                                <Button variant="primary" className="btn-block rounded-0">Upload Photo</Button>
                            </Card.Body>)
                        }
                    </Card>
                </Col>
                <Col md={8}>
                    <div className="container">
                        <Row>
                            {
                                form_1.map((form_data, index) => {
                                    return (
                                        <Col md={{span:10,ofset:2}} key={index}>
                                            <Form.Group as={Row} className="text-light">
                                                <Form.Label column md="4" >
                                                    {form_data.label}
                                                </Form.Label>
                                                {
                                                    viewOnly ?
                                                        (<Form.Label column md="4">{profile[form_data.name]}</Form.Label >)
                                                        :
                                                        (<Col md={8} ><Form.Control type="text" size="sm" name={form_data.name} className="form-new" /></Col>)
                                                }
                                            </Form.Group>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                        <Row>
                            {

                                form_2.map((formdata, key) => {
                                    return (
                                        <Col key={key}  md={{span:10,ofset:2}} offset={2}>
                                            <Form.Group as={Row}>
                                                <Form.Label column md="4" className="text-light">
                                                    {formdata.label}
                                                </Form.Label>
                                                <Col md={8} className="mb-1">
                                                    <Select className="text-dark form-new" options={options} isMulti={formdata.isMulti} name={formdata.name}  />
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </div>
                </Col>
            </Row>


        </div>

    )
}
