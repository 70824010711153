import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import 'bootstrap/dist/css/bootstrap.min.css'
import { store } from './store/store';
import reportWebVitals from './reportWebVitals';
import Routes from './routes/routes';
import { Provider } from 'react-redux';
import { Provider as WebSocketProvider } from "./WebSocket";
import KeycloakProvider from './KeycloakProvider';
KeycloakProvider
ReactDOM.render(
  <KeycloakProvider>
    <WebSocketProvider>
      <Provider store={store}>
        <Routes />
      </Provider>
    </WebSocketProvider>
  </KeycloakProvider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
