import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Nav from "react-bootstrap/Nav";
import { Form, FormControl } from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";
import EquityDetails from "./Equity/EquityDetails";
import BondDetails from "./Bond/BondDetails";
import FXDetails from "./FX/FXDetails";

const Security = () => {
  const itemsData = [
    {
      id: 1,
      name: "MS",
      title: "Equity",
    },
  ];
  const [searchText, setSearchText] = useState(null);
  const [filterData, setFilterData] = useState([]);
  const [navLinkMenu, setNavLink] = useState([]);
  const [items, setItems] = useState(itemsData);
  const [mainmenu, setMainmenu] = useState(null);
  const [activeTabType, setActiveTabType] = useState([]);
  const [activeTab, setActiveTab] = useState(null);
  const handleSearchInput = ({ target: { value } }) => {
    setSearchText(value);
    if (!value) {
      setFilterData([]);
      return;
    }
    setFilterData(
      items.filter((e) => e.name.toLowerCase().includes(value.toLowerCase()))
    );
    setLocalStorageDataHandler();
  };

  const setLocalStorageDataHandler = () => {
    localStorage.setItem(
      "Security",
      JSON.stringify({ navLinkMenu, items, filterData, mainmenu })
    );
  };

  const handleSelect = (e, item) => {
    setFilterData([]);
    setActiveTabType((oldArray) => {
      let tab =
        (item.title === "Equity" && <EquityDetails />) ||
        (item.title === "Bond" && <BondDetails />) ||
        (item.title === "FX" && <FXDetails />);
      setActiveTab(tab);
      return [
        ...oldArray,
        {
          tabData: tab,
          id: item.id,
        },
      ];
    });

    setSearchText(item.name);
    setNavLink((oldArray) => [
      ...oldArray,
      {
        label: item.name,
        title: item.title,
        id: item.id,
      },
    ]);
    const findIndex = items.findIndex((element) => element.name === item.name);
    const findIndexFilterData = filterData.findIndex(
      (element) => element.name === item.name
    );

    if (findIndex !== -1) {
      items.splice(findIndex, 1);
      setItems(items);
      filterData.splice(findIndexFilterData, 1);
      setFilterData(filterData);
    }
    setSearchText("");
    setMainmenu(item.id);
    setFilterData([]);
  };

  useEffect(() => {
    if (!navLinkMenu.length) return;
    localStorage.setItem(
      "Security",
      JSON.stringify({ navLinkMenu, items, filterData, mainmenu })
    );
    let menuTabs = [];
    navLinkMenu.map((item) => {
      menuTabs.push({
        tabData:
          (item.title === "Equity" && <EquityDetails />) ||
          (item.title === "Bond" && <BondDetails />) ||
          (item.title === "FX" && <FXDetails />),
        id: item.id,
      });
    });

    setActiveTabType(menuTabs);
  }, [navLinkMenu , mainmenu]);

  const handleTabClick = ({ id, title }, e) => {
    e.stopPropagation();

    const findIndexFilterData = activeTabType.findIndex(
      (element) => element.id === Number(id)
    );

    setActiveTab(activeTabType[findIndexFilterData].tabData);
    setMainmenu(id);

  };

  const onTabClose = (e, id) => {
    e.stopPropagation();
    const findIndex = navLinkMenu.findIndex((element) => element.id === id);

    if (findIndex !== -1) {
      navLinkMenu.splice(findIndex, 1);
      setNavLink(navLinkMenu);
    }

    const findIndexTab = activeTabType.findIndex(
      (element) => element.id === id
    );

    if (findIndexTab !== -1) {
      if (activeTabType.length >= 1) {
        if (activeTabType.length === 1) {
          setActiveTab(null);
        } else {
          if (findIndexTab === 0) {
            if(navLinkMenu.length > 1){
              setActiveTab(activeTabType[findIndexTab + 1].tabData);
              setMainmenu(navLinkMenu[findIndex + 1].id);
            }
          } else {
            if(navLinkMenu.length === findIndex ){
              setActiveTab(activeTabType[findIndexTab - 1].tabData);
              setMainmenu(navLinkMenu[findIndex - 1].id);
            } 
          }
        }
      } else {
        setActiveTab(null);
        setActiveTabType(null);
      }
      activeTabType.splice(findIndexTab, 1);
      if (activeTabType.length === 1) {
        setMainmenu(navLinkMenu[0].id);
      }
      setActiveTabType(activeTabType);
    }
    setLocalStorageDataHandler();
  };

  useEffect(() => {
    let option = JSON.parse(localStorage.getItem("Security"));
    if (option && Object.keys(option).length > 1) {
      setNavLink(option.navLinkMenu);

      if (option.mainmenu) {
        let tabs = option.navLinkMenu.filter((e) => e.id === option.mainmenu);
        if (tabs.length) {
          let tab =
            (tabs[0].title === "Equity" && <EquityDetails />) ||
            (tabs[0].title === "Bond" && <BondDetails />) ||
            (tabs[0].title === "FX" && <FXDetails />);
          setActiveTab(tab);
        }
      setMainmenu(option.mainmenu);
      }
    }
  }, []);
  return (
    <div className="main-menu  security row m-1">
      <div className="col-lg-12 col-md-12 p-0">
        <div className="row">
          <div className="col-sm-4 col-4 col-lg-3 col-xs-3  w-100 pr-0">
            <div className="p-0 mb-lg-0 mb-md-0 mb-3">
              <Form className="tab-form-list-group" inline>
                <FormControl
                  onChange={(e) => handleSearchInput(e)}
                  value={searchText || ""}
                  type="text"
                  placeholder="Search"
                  className="tab-content-input w-100"
                />
                {filterData.length > 0 && (
                  <ListGroup className="w-100 tab-list-group">
                    {filterData.map((item, i) => (
                      <ListGroup.Item
                        onClick={(e) => handleSelect(e, item)}
                        className="tab-list-group-item"
                        key={i}
                      >
                        <p className="list-title">{item.title}:</p>
                        <p className="list-label m-0">{item.name}</p>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                )}
              </Form>
            </div>
          </div>
          <div className="col-sm-8 col-8 col-lg-9 col-xs-9">
            <Nav className="bond-menu pb-0 px-3 h-100" fill variant="tabs">
              <div className="row">
                {navLinkMenu.map((item, i) => (
                  <Nav.Item className="p-0" key={i}>
                    <Nav.Link
                      eventKey={`link-${i}-${item.id}`}
                      className={mainmenu === +item.id ? "active" : ""}
                      onClick={(e) => handleTabClick(item, e)}
                    >
                      <div className="d-flex align-items-baseline">
                        <p className="list-title mb-0 mr-2">{item.title}:</p>{" "}
                        <p className="mb-0">{item.label}</p>
                        <i
                          onClick={(e) => onTabClose(e, item.id)}
                          className="fa fa-times-circle ml-2"
                        ></i>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </div>
            </Nav>
          </div>
        </div>
      </div>

      {mainmenu && (
        <div className="col-lg-12 tab-container p-0">
          <div className="tab-content tab-content-custom h-100 p-15">
            {activeTab}
          </div>
        </div>
      )}
    </div>
  );
};

export default Security;
