import React from 'react'
import { Spinner } from "react-bootstrap"
import "./Spinner.css"
export default function Preloader({animation,size,variant}) {
    return (
        <div className="spinner-wrapper">
            <Spinner animation={animation ?? "grow"} variant={variant ?? "primary"} size={size ??  "lg"} />
        </div>
    )
}
