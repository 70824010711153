import React from 'react';
import TabContent from '../../components/HigherOrderComponent/TabContent';
import Fund from "./Fund/Fund";
import Strategy from "./Strategy/Strategy";
import SubStrategy from './SubStrategy/SubStrategy';
import Group from './Group/Group';
import Assignment from './Assignment/Assignment';
import PerformanceReport from './PerformanceReport/PerformanceReport';
import "./FundAdmin.scss"
export default function FundAdmin() {
    const tabMenu = [
        {
            title: "Funds",
            component: <Fund />
        },
        {
            title: "Strategy",
            component: <Strategy />
        },

        {
            title: "Sub Strategy",
            component: <SubStrategy />
        },
        {
            title: "Group",
            component: <Group />
        },
        {
            title: "Assignment",
            component: <Assignment />
        },
        {
            title: "Performance Report",
            component: <PerformanceReport />
        }
    ]
    return (
        <div className='h-100'>
            < TabContent tabMenu={tabMenu} />
        </div>
    )
}
