import React, { useEffect, useState } from "react";
import Blotter from "./Blotter";
import OrderEntry from "./OrderEntry";
import OrderSummary from "./OrderSummary";
import Nav from "react-bootstrap/Nav";
import {
  BrowserRouter as Router,
  Link,
  Route,
  useRouteMatch,
} from "react-router-dom";
const Order = () => {
  const { url, path } = useRouteMatch();
  const [tabIndex, setTabIndex] = useState(0);

  const tabMenu = [
    {
      title: "Summary",
      component: <OrderSummary />,
      link: "/summary",
    },
    {
      title: "Blotter",
      component: <Blotter />,
      link: "/blotter",
    },
    {
      title: "Order Entry",
      component: <OrderEntry />,
      link: "/order-entry",
    },
  ];

  useEffect(() => {
    tabMenu.map((e, i) => {
      if (window.location.pathname.includes(e.link)) {
        setTabIndex(i);
      }
    });
  }, []);
  return (
    <Router>
      <div className="order row main-menu m-0 p-0">
        <div className="col-lg-6 col-md-4 col-sm-12 p-0">
          <Nav className="bond-menu" fill variant="tabs">
            {tabMenu.map((_, index) => {
              return (
                <Nav.Item key={index}>
                  <Nav.Link
                    as={Link}
                    onClick={() => setTabIndex(index)}
                    to={`${url}${_.link}`}
                    eventKey={`link-${index}`}
                    className={tabIndex === index ? "active" : ""}
                  >
                    {_.title}
                  </Nav.Link>
                </Nav.Item>
              );
            })}
          </Nav>
        </div>
        <div className="col-lg-12 dashboard-tab-content p-0">
          <div className="tab-content tab-content-custom h-100 p-3">
            {tabMenu.map((_, index) => {
              return (
                <Route key={index} exact={_.exact} path={`${path}${_.link}`}>
                  {_.component}
                </Route>
              );
            })}
          </div>
        </div>
      </div>
    </Router>
  );
};

export default Order;
