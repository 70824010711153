
import { Form } from "react-bootstrap";
import styles from './Trade.module.scss';
import MultiActionBtn from '../../components/HigherOrderComponent/Button/MultiActionBtn'

let data = [
    { label: "Symbol:", value: "AAPL" },
    { label: "Company Name:", value: "CAP NAT" },
  ];

  let buttonSet1= ["FILL" , 'BUY' , 'SELL' , 'QUOTE'] 
  let buttonSet2= ["LIMIT" , 'SIZE' , 'PRICE' , 'T+1'] 

  let list = [
      {label:'PX:' ,value:'PX'},
      {label:'B/O:' ,value:'B/O'},
      {label:'Last Px:' ,value:'Last Px'},

      {label:'Last Qty:' ,value:'Last Qty'},

      {label:'Daily H/L:' ,value:'100'},

      {label:'52 Wk H/L:' ,value:'100'},

  ]
const TradeEntry = () => {
    return(
        <div className='panel panel-default h-100'>
              <div className="panel-body paneldecoration content-dotted-border">
        <div className="row">
        {data.map((column, column_key) => (
            <div key={column_key} className="col-md-12 mb-2">
              <div className="row align-items-center m-1">
                <div className="col-md-6 font-weight-bold p-0">
                  <Form.Label className="mb-0">{column.label}</Form.Label>
                </div>
                <div className="col-md-6 p-0">
                  {column.value}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="row">
        {buttonSet1.map((column, column_key) => (
            <div key={column_key}  className="col-md-4">
                <MultiActionBtn variant="transparent" className="mb-3" onClick={() => console.log("")} size="sm" label={column} /> 
            </div>
        ))}
        </div>
        <div className="row">
        {buttonSet2.map((column, column_key) => (
            <div key={column_key}  className="col-md-4">
                <MultiActionBtn variant="transparent" className="mb-3" onClick={() => console.log("")} size="sm" label={column} /> 
            </div>
        ))}
        </div>
        </div>
        <div className="panel-body paneldecoration">
        <div className="row">
        {list.map((column, column_key) => (
            <div key={column_key} className="col-md-6 mb-2">
              <div className="row align-items-center m-1">
                <div className="col-md-6 font-weight-bold p-0">
                  <Form.Label className="mb-0">{column.label}</Form.Label>
                </div>
                <div className="col-md-6 p-0">
                  {column.value}
                </div>
              </div>
            </div>
          ))}
        </div>
        </div>
            </div>
    )
    }
    
    export default TradeEntry