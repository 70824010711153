import { useEffect, useState } from 'react'
import { Row, Col , Modal , Button} from 'react-bootstrap'
import FormGenerator from '../FormGenerator';
import { group_tableData as tableData } from '../tableData';
import { DataTable, checkBoxColumn } from '../../../components/HigherOrderComponent/DataTable';
import * as yup from 'yup';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { ConfirmDialog } from '../../../components/Confirmation';
import { deleteFromLocal, saveToLocal } from '../../../common/localStorage.service';



export default function Group() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            getRowData();
            setLoading(false)
        }, 3000)
    }, []);

    const getRowData = () => {
        let d = localStorage.getItem("allGroups");
        setData(d ? JSON.parse(d) : []);
    }

    const deleteHandler = (cb) => {
        deleteFromLocal({ collectionName: "allGroups", collectionId: "group_id", selectedId: selectedRow.group_id })
        setSelectedRow(null);
        getRowData();
        cb();
        return;
    }

    const onSubmit = (values, { setSubmitting, resetForm, setFieldError }) => {
        setSubmitting(true);
        setTimeout(() => {
            saveToLocal({ values,collectionName: "allGroups", collectionId: "group_id", selectedId: selectedRow ? selectedRow.group_id : null });
            resetForm();
            setSubmitting(false);
            getRowData();
        }, 2000)
    }

    const onDelete = () => {
        if (selectedRow) {
            {
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return <ConfirmDialog onClose={onClose} onConfirm={deleteHandler} />
                    }
                });
            }
        }
    }

    const onRowSelect = ({ selectedFlatRows, selectedRowIds }) => {
        setSelectedRow(selectedFlatRows[0] ? selectedFlatRows[0].original : null);
    }

    const fundsFormData = [
        {
            label: "Group Name",
            label_col: 5,
            input_col: 7,
            type: "text",
            name: "group_name",
            validations: yup.string().required("Please enter group name"),

        },
        {
            label: "Group Type:",
            label_col: 5,
            input_col: 7,
            type: "select",
            name: "group_type",
            validations: yup.string().required("Please select group type"),
            options: ["Fund", "Strategy", "Risk", "Admin"]
        },
        {
            label: "Description",
            label_col: 5,
            input_col: 7,
            type: "text-area",
            name: "description",
            validations: yup.string().required("Please enter description"),

        },
    ];

    let schema = yup.object().shape({
        ...fundsFormData.reduce(
            (obj, item) => Object.assign(obj, { [item.name]: item.validations ?? "" }), {})
    });


    const config = {
        tableCard: {
            classes: ["h-100"]
        },
        tableWrapper: {
            classes: ["h-100"]
        }
    }

    return (
        <>
            <Row className="h-100 pb-4">
                <Col md={8} className="h-100" >
                    <DataTable
                        pagination={true}
                        columnData={[...checkBoxColumn, ...tableData]}
                        data={data}
                        config={config}
                        loading={loading}
                        onRowSelect={onRowSelect}
                    />
                </Col>
                <Col md={4}>
                    <FormGenerator
                        formData={fundsFormData}
                        initialData={selectedRow}
                        onSubmit={onSubmit}
                        onDelete={onDelete}
                        schema={schema}
                        title={"Fund Details"}
                    />
                </Col>
            </Row>
        </>
    )
}
