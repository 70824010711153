import React, { useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Nav from 'react-bootstrap/Nav'
import DatePicker from 'react-datepicker'
import Button from 'react-bootstrap/Button'
import { cashflowTableData } from '../../utilities/tablefields'
const Bond = props => {
  const [menu, setMenu] = useState(1)
  const [submenu, setSubmenu] = useState(1)
  const [startDate, setStartDate] = useState(new Date())
  const { modal } = props

  return (
    <>
      {!modal && (
        <div className='row pl-2'>
          <div className='col-md-12 breadcumtext'>
            <h3 className='page-title'>Bond Management</h3>

            <ul className='page-breadcrumb breadcrumb '>
              <li>
                <i className='fa fa-exchange'></i> Trade capture
                <i className='fa fa-angle-right'></i>
              </li>
              <li>
                <i className='fa fa-money'></i> Fixed Income
                <i className='fa fa-angle-right'></i>
              </li>
              <li>
                <i className='fa fa-caret-square-o-up'></i> Bond
              </li>
            </ul>
          </div>
        </div>
      )}

      <div className={modal ? 'col-md-5 both' : 'col-md-5 both modal-padding'}>
        <div className='row'>
          <div className='col-md-12'>
            <div className='portlet solid blue2'>
              <div className='portlet-body'>
                <div className='form-horizontal' role='form'>
                  <div className='form-group'>
                    <div className='col-md-4 both'>
                      <label className='control-label bond-label'>Cusip:</label>
                    </div>
                    <div className='col-md-8 both'>
                      <input
                        className='form-control input-sm'
                        id='bond_cusip'
                      />
                    </div>
                  </div>
                  <div className='form-group'>
                    <div className='col-md-4 both'>
                      <label className='control-label bond-label'>ISIN:</label>
                    </div>
                    <div className='col-md-8 both'>
                      <input className='form-control input-sm' id='bond_isin' />
                    </div>
                  </div>
                  <div className='form-group'>
                    <div className='col-md-4 both'>
                      <label className='control-label bond-label'>
                        Orderid:
                      </label>
                    </div>
                    <div className='col-md-8 both'>
                      <input
                        className='form-control input-sm'
                        id='bond_orderid'
                      />
                    </div>
                  </div>
                  <div className='form-group'>
                    <div className='col-md-12'>
                      <input
                        type='hidden'
                        className='form-control input-sm'
                        id='bond_guid'
                        value='bed39f44-068e-4cd5-bd68-db9b0f129f6c'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <Nav className='bond-menu' fill variant='tabs'>
              <Nav.Item>
                <Nav.Link
                  eventKey='link-0'
                  className={menu === 1 ? 'active' : ''}
                  onClick={() => setMenu(1)}
                >
                  Reference
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey='link-1'
                  className={menu === 2 ? 'active' : ''}
                  onClick={() => setMenu(2)}
                >
                  Order
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey='link-2'
                  className={menu === 3 ? 'active' : ''}
                  onClick={() => setMenu(3)}
                >
                  Executions
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey='link-3'
                  className={menu === 4 ? 'active' : ''}
                  onClick={() => setMenu(4)}
                >
                  Transactions
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <div className='tab-content tab-content-custom'>
              <div className={menu === 1 ? 'show' : 'hide'}>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      Ticker:
                    </label>
                    <div className='col-md-7 both'>
                      <input className='form-control input-sm' />
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      BBG Ticker:
                    </label>
                    <div className='col-md-7 both'>
                      <input className='form-control input-sm' />
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      Description:
                    </label>
                    <div className='col-md-7 both'>
                      <input className='form-control input-sm' />
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      Issue Date:
                    </label>
                    <div className='col-md-7 both bond'>
                      <DatePicker
                        selected={startDate}
                        onChange={date => setStartDate(date)}
                      />
                      {/* <input className='form-control input-sm' /> */}
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      Maturity Date:
                    </label>
                    <div className='col-md-7 both bond'>
                      <DatePicker
                        selected={startDate}
                        onChange={date => setStartDate(date)}
                      />
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      Coupon:
                    </label>
                    <div className='col-md-7 both'>
                      <input className='form-control input-sm' />
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      Coupon Type:
                    </label>
                    <div className='col-md-7 both'>
                      <input className='form-control input-sm' />
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      Currency:
                    </label>
                    <div className='col-md-7 both'>
                      <input className='form-control input-sm' />
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      Basis:
                    </label>
                    <div className='col-md-7 both'>
                      <input className='form-control input-sm' />
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      First Coupon Date:
                    </label>
                    <div className='col-md-7 both'>
                      <input className='form-control input-sm' />
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      Callable:
                    </label>
                    <div className='col-md-7 both'>
                      <input className='form-control input-sm' />
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      Amount Issued:
                    </label>
                    <div className='col-md-7 both'>
                      <input className='form-control input-sm' />
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      AMT Outstanding:
                    </label>
                    <div className='col-md-7 both'>
                      <input className='form-control input-sm' />
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      Frequency:
                    </label>
                    <div className='col-md-7 both'>
                      <DropdownButton
                        id='dropdown-basic-button'
                        title='Please Choose:'
                        variant='default'
                        size='lg'
                        menuAlign='left'
                        className='form-control input-sm modal-dropdown'
                      >
                        <Dropdown.Item eventKey='option-1'>
                          Annual
                        </Dropdown.Item>
                        <Dropdown.Item eventKey='option-2'>
                          Semi-annual
                        </Dropdown.Item>
                        <Dropdown.Item eventKey='option-3'>
                          Quarterly
                        </Dropdown.Item>
                        <Dropdown.Item eventKey='option-4'>
                          Monthly
                        </Dropdown.Item>
                      </DropdownButton>
                      {/* <input className='form-control input-sm' /> */}
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      Days To Settle:
                    </label>
                    <div className='col-md-7 both'>
                      <input className='form-control input-sm' />
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      Price/Yield:
                    </label>
                    <div className='col-md-7 both'>
                      <DropdownButton
                        id='dropdown-basic-button'
                        title='Please Choose:'
                        variant='default'
                        size='lg'
                        menuAlign='left'
                        className='form-control input-sm modal-dropdown'
                      >
                        <Dropdown.Item eventKey='option-1'>Price</Dropdown.Item>
                        <Dropdown.Item eventKey='option-2'>Yield</Dropdown.Item>
                      </DropdownButton>
                      {/* <input className='form-control input-sm' /> */}
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      Value:
                    </label>
                    <div className='col-md-7 both'>
                      <input className='form-control input-sm' />
                    </div>
                  </div>
                </div>
              </div>

              <div className={menu === 2 ? 'show' : 'hide'}>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      Order Date:
                    </label>
                    <div className='col-md-7 both bond'>
                      <DatePicker
                        selected={startDate}
                        onChange={date => setStartDate(date)}
                      />
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      Notional:
                    </label>
                    <div className='col-md-7 both'>
                      <input className='form-control input-sm' />
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      BuySell:
                    </label>
                    <div className='col-md-7 both'>
                      <DropdownButton
                        id='dropdown-basic-button'
                        title='Please Choose:'
                        variant='default'
                        size='lg'
                        menuAlign='left'
                        className='form-control input-sm modal-dropdown'
                      >
                        <Dropdown.Item eventKey='option-1'>Buy</Dropdown.Item>
                        <Dropdown.Item eventKey='option-2'>Sell</Dropdown.Item>
                      </DropdownButton>
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      Order Price:
                    </label>
                    <div className='col-md-7 both'>
                      <input className='form-control input-sm' />
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      CCY:
                    </label>
                    <div className='col-md-7 both'>
                      <DropdownButton
                        id='dropdown-basic-button'
                        title='Please Choose:'
                        variant='default'
                        size='lg'
                        menuAlign='left'
                        className='form-control input-sm modal-dropdown'
                      >
                        <Dropdown.Item eventKey='option-1'>USD</Dropdown.Item>
                        <Dropdown.Item eventKey='option-2'>AUD</Dropdown.Item>
                        <Dropdown.Item eventKey='option-3'>Euro</Dropdown.Item>
                      </DropdownButton>
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      Coupon:
                    </label>
                    <div className='col-md-7 both'>
                      <input className='form-control input-sm' />
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      Maturity:
                    </label>
                    <div className='col-md-7 both'>
                      <input className='form-control input-sm' />
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      Freq:
                    </label>
                    <div className='col-md-7 both'>
                      <DropdownButton
                        id='dropdown-basic-button'
                        title='Please Choose:'
                        variant='default'
                        size='lg'
                        menuAlign='left'
                        className='form-control input-sm modal-dropdown'
                      >
                        <Dropdown.Item eventKey='option-1'>
                          Annual
                        </Dropdown.Item>
                        <Dropdown.Item eventKey='option-2'>
                          Semi-annual
                        </Dropdown.Item>
                        <Dropdown.Item eventKey='option-3'>
                          Quarterly
                        </Dropdown.Item>
                        <Dropdown.Item eventKey='option-4'>
                          Monthly
                        </Dropdown.Item>
                      </DropdownButton>
                    </div>
                  </div>
                </div>
                {modal && (
                  <div className='form-horizontal'>
                    <div className='form-group form-group-custom'>
                      <div className='col-md-12'>
                        <Button variant='success' className='pull-right'>
                          <i className='fa fa-save'></i> Save Order
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
                {!modal && (
                  <div className='form-horizontal'>
                    <div className='row'>
                      <div className='col-md-4'>
                        <Button variant='success' className='pull-right'>
                          <i className='fa fa-save'></i> Create Order
                        </Button>
                      </div>
                      <div className='col-md-4'>
                        <Button variant='success' className='pull-right'>
                          <i className='fa fa-save'></i> Claim Order
                        </Button>
                      </div>
                      <div className='col-md-4'>
                        <Button variant='success' className='pull-right'>
                          <i className='fa fa-save'></i> Get Order
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className={menu === 3 ? 'show ' : 'hide '}>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      Trade Date:
                    </label>
                    <div className='col-md-7 both bond'>
                      <DatePicker
                        selected={startDate}
                        onChange={date => setStartDate(date)}
                      />
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      Settle Date:
                    </label>
                    <div className='col-md-7 both bond'>
                      <DatePicker
                        selected={startDate}
                        onChange={date => setStartDate(date)}
                      />
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      Notional:
                    </label>
                    <div className='col-md-7 both'>
                      <input className='form-control input-sm' />
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      BuySell:
                    </label>
                    <div className='col-md-7 both'>
                      <DropdownButton
                        id='dropdown-basic-button'
                        title='Please Choose:'
                        variant='default'
                        size='lg'
                        menuAlign='left'
                        className='form-control input-sm modal-dropdown'
                      >
                        <Dropdown.Item eventKey='option-1'>Buy</Dropdown.Item>
                        <Dropdown.Item eventKey='option-2'>Sell</Dropdown.Item>
                      </DropdownButton>
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      CCY:
                    </label>
                    <div className='col-md-7 both'>
                      <DropdownButton
                        id='dropdown-basic-button'
                        title='Please Choose:'
                        variant='default'
                        size='lg'
                        menuAlign='left'
                        className='form-control input-sm modal-dropdown'
                      >
                        <Dropdown.Item eventKey='option-1'>USD</Dropdown.Item>
                        <Dropdown.Item eventKey='option-2'>AUD</Dropdown.Item>
                        <Dropdown.Item eventKey='option-3'>Euro</Dropdown.Item>
                      </DropdownButton>
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      Coupon:
                    </label>
                    <div className='col-md-7 both'>
                      <input className='form-control input-sm' />
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      Freq:
                    </label>
                    <div className='col-md-7 both'>
                      <DropdownButton
                        id='dropdown-basic-button'
                        title='Please Choose:'
                        variant='default'
                        size='lg'
                        menuAlign='left'
                        className='form-control input-sm modal-dropdown'
                      >
                        <Dropdown.Item eventKey='option-1'>
                          Annual
                        </Dropdown.Item>
                        <Dropdown.Item eventKey='option-2'>
                          Semi-annual
                        </Dropdown.Item>
                        <Dropdown.Item eventKey='option-3'>
                          Quarterly
                        </Dropdown.Item>
                        <Dropdown.Item eventKey='option-4'>
                          Monthly
                        </Dropdown.Item>
                      </DropdownButton>
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      Price:
                    </label>
                    <div className='col-md-7 both'>
                      <input className='form-control input-sm' />
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      Yield:
                    </label>
                    <div className='col-md-7 both'>
                      <input className='form-control input-sm' />
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      Maturity Date:
                    </label>
                    <div className='col-md-7 both bond'>
                      <DatePicker
                        selected={startDate}
                        onChange={date => setStartDate(date)}
                      />
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      Comments:
                    </label>
                    <div className='col-md-7 both'>
                      <input className='form-control input-sm' />
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      Execution Id:
                    </label>
                    <div className='col-md-7 both'>
                      <input className='form-control input-sm' />
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <div className='col-md-12'>
                      <Button variant='info' className='pull-right'>
                        <i className='fa fa-play'></i> Execute
                      </Button>
                    </div>
                  </div>
                </div>
              </div>

              <div className='tab-pane' id='transactions'></div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          modal
            ? 'col-md-7 both position'
            : 'col-md-7 both position modal-padding'
        }
      >
        <Nav className='bond-menu ' fill variant='tabs'>
          <Nav.Item>
            <Nav.Link
              eventKey='link-0'
              className={submenu === 1 ? 'active' : ''}
              onClick={() => setSubmenu(1)}
            >
              Booking
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey='link-1'
              className={submenu === 2 ? 'active' : ''}
              onClick={() => setSubmenu(2)}
            >
              Pricing
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey='link-2'
              className={submenu === 3 ? 'active' : ''}
              onClick={() => setSubmenu(3)}
            >
              Cashflow
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey='link-3'
              className={submenu === 4 ? 'active' : ''}
              onClick={() => setSubmenu(4)}
            >
              Risks
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <div className='tab-content tab-content-custom '>
          <div className={submenu === 1 ? 'show' : 'hide'}>
            <div className='row'>
              <div className='col-md-5'>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      Strategy:
                    </label>
                    <div className='col-md-7 both'>
                      <DropdownButton
                        id='dropdown-basic-button'
                        title='Please Choose:'
                        variant='default'
                        size='lg'
                        menuAlign='left'
                        className='form-control input-sm modal-dropdown booking-dropdown'
                      >
                        <Dropdown.Item eventKey='option-1'>Buy</Dropdown.Item>
                        <Dropdown.Item eventKey='option-2'>Sell</Dropdown.Item>
                      </DropdownButton>
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      SubStrat:
                    </label>
                    <div className='col-md-7 both'>
                      <DropdownButton
                        id='dropdown-basic-button'
                        title='Please Choose:'
                        variant='default'
                        size='lg'
                        menuAlign='left'
                        className='form-control input-sm modal-dropdown booking-dropdown'
                      >
                        <Dropdown.Item eventKey='option-1'>Buy</Dropdown.Item>
                        <Dropdown.Item eventKey='option-2'>Sell</Dropdown.Item>
                      </DropdownButton>
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      CounterParty:
                    </label>
                    <div className='col-md-7 both'>
                      <DropdownButton
                        id='dropdown-basic-button'
                        title='Please Choose:'
                        variant='default'
                        size='lg'
                        menuAlign='left'
                        className='form-control input-sm modal-dropdown booking-dropdown'
                      >
                        <Dropdown.Item eventKey='option-1'>Buy</Dropdown.Item>
                        <Dropdown.Item eventKey='option-2'>Sell</Dropdown.Item>
                      </DropdownButton>
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      Owner:
                    </label>
                    <div className='col-md-7 both'>
                      <input className='form-control input-sm' />
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      Trader:
                    </label>
                    <div className='col-md-7 both'>
                      <DropdownButton
                        id='dropdown-basic-button'
                        title='Please Choose:'
                        variant='default'
                        size='lg'
                        menuAlign='left'
                        className='form-control input-sm modal-dropdown booking-dropdown'
                      >
                        <Dropdown.Item eventKey='option-1'>Buy</Dropdown.Item>
                        <Dropdown.Item eventKey='option-2'>Sell</Dropdown.Item>
                      </DropdownButton>
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      RiskGroup:
                    </label>
                    <div className='col-md-7 both'>
                      <DropdownButton
                        id='dropdown-basic-button'
                        title='Please Choose:'
                        variant='default'
                        size='lg'
                        menuAlign='left'
                        className='form-control input-sm modal-dropdown booking-dropdown'
                      >
                        <Dropdown.Item eventKey='option-1'>Buy</Dropdown.Item>
                        <Dropdown.Item eventKey='option-2'>Sell</Dropdown.Item>
                      </DropdownButton>
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      TrdeGroup:
                    </label>
                    <div className='col-md-7 both'>
                      <DropdownButton
                        id='dropdown-basic-button'
                        title='Please Choose:'
                        variant='default'
                        size='lg'
                        menuAlign='left'
                        className='form-control input-sm modal-dropdown booking-dropdown'
                      >
                        <Dropdown.Item eventKey='option-1'>Buy</Dropdown.Item>
                        <Dropdown.Item eventKey='option-2'>Sell</Dropdown.Item>
                      </DropdownButton>
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      Priority:
                    </label>
                    <div className='col-md-7 both'>
                      <input className='form-control input-sm' />
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-7'>
                <div className='form-horizontal'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='form-group-custom col-md-4 both'>
                        <label className=' control-label bond-label'>
                          Fund
                        </label>
                      </div>
                      <div className='form-group-custom col-md-4 both'>
                        <label className=' control-label bond-label'>PCT</label>
                      </div>
                      <div className='form-group-custom col-md-4 both'>
                        <label className=' control-label bond-label'>
                          Notional
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='row margin10'>
                    <div className='col-md-12'>
                      <div className='form-group-custom col-md-4 both'>
                        <DropdownButton
                          id='dropdown-basic-button'
                          title='Please Choose:'
                          variant='default'
                          size='lg'
                          menuAlign='left'
                          className='form-control input-sm modal-dropdown fund-dropdown'
                        >
                          <Dropdown.Item eventKey='option-1'>Buy</Dropdown.Item>
                          <Dropdown.Item eventKey='option-2'>
                            Sell
                          </Dropdown.Item>
                        </DropdownButton>
                      </div>
                      <div className='form-group-custom col-md-4 both'>
                        <input className='form-control input-sm' />
                      </div>
                      <div className='form-group-custom col-md-4 both'>
                        <input className='form-control input-sm' />
                      </div>
                    </div>
                  </div>
                  <div className='row margin10'>
                    <div className='col-md-12'>
                      <div className='form-group-custom col-md-4 both'>
                        <DropdownButton
                          id='dropdown-basic-button'
                          title='Please Choose:'
                          variant='default'
                          size='lg'
                          menuAlign='left'
                          className='form-control input-sm modal-dropdown fund-dropdown'
                        >
                          <Dropdown.Item eventKey='option-1'>Buy</Dropdown.Item>
                          <Dropdown.Item eventKey='option-2'>
                            Sell
                          </Dropdown.Item>
                        </DropdownButton>
                      </div>
                      <div className='form-group-custom col-md-4 both'>
                        <input className='form-control input-sm' />
                      </div>
                      <div className='form-group-custom col-md-4 both'>
                        <input className='form-control input-sm' />
                      </div>
                    </div>
                  </div>
                  <div className='row margin10'>
                    <div className='col-md-12 '>
                      <div className='form-group-custom col-md-4 both'>
                        <DropdownButton
                          id='dropdown-basic-button'
                          title='Please Choose:'
                          variant='default'
                          size='lg'
                          menuAlign='left'
                          className='form-control input-sm modal-dropdown fund-dropdown'
                        >
                          <Dropdown.Item eventKey='option-1'>Buy</Dropdown.Item>
                          <Dropdown.Item eventKey='option-2'>
                            Sell
                          </Dropdown.Item>
                        </DropdownButton>
                      </div>
                      <div className='form-group-custom col-md-4 both'>
                        <input className='form-control input-sm' />
                      </div>
                      <div className='form-group-custom col-md-4 both'>
                        <input className='form-control input-sm' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={submenu === 2 ? 'show' : 'hide'}>
            <div className='row'>
              <div className='col-md-6'>
                {' '}
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      Value Date:
                    </label>
                    <div className='col-md-7 both bond'>
                      <DatePicker
                        selected={startDate}
                        onChange={date => setStartDate(date)}
                      />
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      Notional:
                    </label>
                    <div className='col-md-7 both'>
                      <input className='form-control input-sm' />
                    </div>
                  </div>
                  <div className='form-group form-group-custom'>
                    <div className='col-md-12'>
                      <Button variant='warning' className='pull-right'>
                        $ Price
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-2 control-label bond-label'>
                      Price /Yield:
                    </label>
                    <div className='col-md-5 both'>
                      <DropdownButton
                        id='dropdown-basic-button'
                        title='Please Choose:'
                        variant='default'
                        size='lg'
                        menuAlign='left'
                        className='form-control input-sm modal-dropdown pricing-dropdown'
                      >
                        <Dropdown.Item eventKey='option-1'>Price</Dropdown.Item>
                        <Dropdown.Item eventKey='option-2'>Yield</Dropdown.Item>
                      </DropdownButton>
                    </div>
                    <div className='col-md-5 both'>
                      <input className='form-control input-sm' />
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom text-align'>
                    <label className='col-md-2 control-label bond-label'>
                      Freq:
                    </label>
                    <div className='col-md-5 both'>
                      <DropdownButton
                        id='dropdown-basic-button'
                        title='Please Choose:'
                        variant='default'
                        size='lg'
                        menuAlign='left'
                        className='form-control input-sm modal-dropdown pricing-dropdown'
                      >
                        <Dropdown.Item eventKey='option-1'>
                          Annual
                        </Dropdown.Item>
                        <Dropdown.Item eventKey='option-2'>
                          Semi-annual
                        </Dropdown.Item>
                        <Dropdown.Item eventKey='option-3'>
                          Quarterly
                        </Dropdown.Item>
                        <Dropdown.Item eventKey='option-4'>
                          Monthly
                        </Dropdown.Item>
                      </DropdownButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className='row'>
              <div className='col-md-6'>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      Yield To Maturity:
                    </label>
                    <div className='col-md-7 both'>
                      <input className='form-control input-sm' />
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      Clean Price:
                    </label>
                    <div className='col-md-7 both'>
                      <input className='form-control input-sm' />
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      Dirty Price:
                    </label>
                    <div className='col-md-7 both'>
                      <input className='form-control input-sm' />
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      Accrued Interest:
                    </label>
                    <div className='col-md-7 both'>
                      <input className='form-control input-sm' />
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      Fees:
                    </label>
                    <div className='col-md-7 both'>
                      <input className='form-control input-sm' />
                    </div>
                  </div>
                  <div className='form-group form-group-custom'>
                    <div className='col-md-12'>
                      <Button variant='success' className='pull-right'>
                        <i className='fa fa-save'></i> Save
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                {' '}
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      Accrued Days:
                    </label>
                    <div className='col-md-7 both'>
                      <input className='form-control input-sm' />
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      Current Factors:
                    </label>
                    <div className='col-md-7 both'>
                      <input className='form-control input-sm' />
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      Principle:
                    </label>
                    <div className='col-md-7 both'>
                      <input className='form-control input-sm' />
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      Interest:
                    </label>
                    <div className='col-md-7 both'>
                      <input className='form-control input-sm' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={submenu === 3 ? 'show' : 'hide'}>
            <div className='row'>
              <div className='col-md-12'>
                {/* <Table tableData={cashflowTableData} /> */}
              </div>
            </div>
          </div>
          <div className={submenu === 4 ? 'show' : 'hide'}>
            <div className='row'>
              <div className='col-md-6'>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      Yield:
                    </label>
                    <div className='col-md-7 both'>
                      <input className='form-control input-sm' />
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      Dur:
                    </label>
                    <div className='col-md-7 both'>
                      <input className='form-control input-sm' />
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      MDur:
                    </label>
                    <div className='col-md-7 both'>
                      <input className='form-control input-sm' />
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      Convexity:
                    </label>
                    <div className='col-md-7 both'>
                      <input className='form-control input-sm' />
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      DV01:
                    </label>
                    <div className='col-md-7 both'>
                      <input className='form-control input-sm' />
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      Basis:
                    </label>
                    <div className='col-md-7 both'>
                      <input className='form-control input-sm' />
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      Y01:
                    </label>
                    <div className='col-md-7 both'>
                      <input className='form-control input-sm' />
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                {' '}
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      ASW:
                    </label>
                    <div className='col-md-7 both'>
                      <input className='form-control input-sm' />
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      ZSpread:
                    </label>
                    <div className='col-md-7 both'>
                      <input className='form-control input-sm' />
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      OASSpread:
                    </label>
                    <div className='col-md-7 both'>
                      <input className='form-control input-sm' />
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      ISpread:
                    </label>
                    <div className='col-md-7 both'>
                      <input className='form-control input-sm' />
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      GSpread:
                    </label>
                    <div className='col-md-7 both'>
                      <input className='form-control input-sm' />
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      TSpread:
                    </label>
                    <div className='col-md-7 both'>
                      <input className='form-control input-sm' />
                    </div>
                  </div>
                </div>
                <div className='form-horizontal'>
                  <div className='form-group form-group-custom'>
                    <label className='col-md-5 control-label bond-label'>
                      MacDur:
                    </label>
                    <div className='col-md-7 both'>
                      <input className='form-control input-sm' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Bond
