import * as yup from "yup";
import { FieldArray, Formik } from "formik";
import { Button, Card, Dropdown, Form } from "react-bootstrap";
import { Form as ReactForm } from "react-bootstrap";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Portal } from "react-overlays";

const mainHeader = [
  { label: "Buy / Sell", width: "w-td-80" },
  { label: "Trade Date", width: "tradeDate" },
  { label: "Settle Date", width: "w-td-80" },
  { label: "Ticker", width: "w-td-80" },
  { label: "Name", width: "tradeDate" },
  { label: "Notional", width: "w-td-90" },
  { label: "Price", width: "w-td-60" },
  { label: "CCY", width: "w-td-60" },
  { label: "Risk Group", width: "w-td-80" },
  { label: "Comments", width: "w-auto" },
  { label: "Status", width: "w-td-90" },
  { label: "Action", width: "w-td-120" },
];

const subHeader = [
  { label: " ", width: "w-td-80" },
  { label: "Alloc (%)", width: "tradeDate" },
  { label: "Fund", width: "w-td-80" },
  { label: "Strategy", width: "w-td-80" },
  { label: "Sub-strategy", width: "tradeDate" },
  { label: "Notional", width: "w-td-90" },
  { label: "Price", width: "w-td-60" },
  { label: "CCY", width: "w-td-60" },
  { label: "MV", width: "w-td-80" },
  { label: "CCY(Base)", width: "w-td-80" },
  { label: "MV(Base)", width: "w-td-60" },
  { label: "%Fund(pre)", width: "w-td-80" },
  { label: "%Fund(post)", width: "w-td-80" },
  { label: "Est Risk(%)", width: "w-td-80" },
  { label: "Est Risk(Base)", width: "w-td-90" },
  { label: "Cash%", width: "w-td-60" },
  { label: "Cash(Base)", width: "w-td-80" },
  { label: "Cash(t+1)", width: "w-td-80" },
  { label: "Cash(t+2)", width: "w-td-80" },
];

const validationSchema = yup.object({
  tradeDate: yup.date().required("Required"),
  settleDate: yup.string().required("Required"),
  riskGroup: yup.string().required("Required"),
  buySell: yup.string().required("Please select group type"),
  ticker: yup.string().required("Required"),
  name: yup.string().required("Required"),
  notional: yup.number().required("Required").min(100),
  price: yup.number().typeError("Required").required("Required"),
  comments: yup.string().required("Required"),
  status: yup.string().default("saved").required().oneOf(["saved",  "submitted"]),
  subRow: yup.array().of(
    yup.object().shape({
      allocation: yup.number().when(["notional"], {
        is: (notional) => !notional,
        then: yup.number().required().max(100).min(0)
      }),
      fund: yup.string().required("Fund required"),
      strategy: yup.string().required("Strategy required"),
      substrategy: yup.string().required("Sub Strategy required"),
      notional: yup.number().typeError("Required").required("Required"),
    })
  ),
});

const getChildInputValidation = (touched, errors, key, index) => {
  return (
    touched.subRow &&
    touched.subRow[index] &&
    touched.subRow[index][key] &&
    errors.subRow &&
    errors.subRow[index] &&
    !!errors.subRow[index][key]
  );
};

const percentage = (percent, total) => {
  return ((percent / 100) * total).toFixed(2)
}

const initialValue = {
  settleDate: "",
  riskGroup: "",
  tradeDate: "",
  buySell: "Buy",
  ticker: "",
  notional: 100,
  price: "",
  comments: "",
  status: "saved",
  subRow: [
    {
      block1: "213",
      allocation: "",
      fund: "",
      strategy: "",
      substrategy: "",
      notional: 0,
      price: 140,
      ccy: "USD",
      mv: "5,600",
      ccyBase: "USD",
      mvBase: "5,600",
      fundPre: "0.02%",
      fundPost: "0.06%",
      estRisk: "0.00%",
      estRiskBase: 280,
      cash: "4.95%",
      cashBase: "4,94,400",
      casht1: "4,94,400",
      casht2: "4,94,400",
    },
  ],
};

const CalendarContainer = ({ children }) => {
  const el = document.getElementById("calendar-portal");
  return <Portal container={el}>{children}</Portal>;
};

function SelectInputFeild({ name, handleChange, handleBlur, value, options = [], isChild = false, touched, index, key, errors }) {
  return (
    <>
      <ReactForm.Control
        as="select"
        custom
        name={name}
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
        size="sm"
        isInvalid={
          isChild && errors
            ? getChildInputValidation(touched, errors, key, index)
            : touched[name] && !!errors[name]
        }
      >
        <option value="">select</option>
        {
          options.map((option, index) => {
            return (
              <option key={index} value={option.value ?? option}>
                {option.title ?? option}
              </option>
            );
          })}
      </ReactForm.Control>
    </>
  );
}

function TextInputFeild({
  type,
  name,
  value,
  handleChange,
  touched,
  errors,
  key,
  index,
  isChild,
  disabled = false,
  readonly = false
}) {
  return (
    <>
      <ReactForm.Control
        type={type}
        size="sm"
        name={name}
        disabled={disabled}
        value={value}
        onChange={handleChange}
        readonly={readonly}
        isInvalid={
          isChild
            ? getChildInputValidation(touched, errors, key, index)
            : touched[name] && !!errors[name]
        }
      />
    </>
  );
}

const TableFormGenerator = ({ handleOnSubmit, deleteDataHandler, onReset }) => {
  const [formValues, setFormValues] = useState([]);



  const onRemoveChildItem = (index) => {
    let newarray = formValues.slice();
    newarray.splice(index, 1);
    setFormValues(newarray);
  };



  return (
    <Card className="w-100 ">
      <Card.Body className="w-100 card-body p-0">
        <Formik
          initialValues={initialValue}
          validationSchema={validationSchema}
          onSubmit={handleOnSubmit}
          onReset={onReset}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            errors,
            touched,
            setFieldValue,
            handleBlur,
            isSubmitting,
            submitForm
          }) =>
          (
            <ReactForm onSubmit={handleSubmit} autocomplete="off">
              <table className="innertable-table parent-table table table-borderless table-striped text-white w-100 ">
                <thead>
                  <tr>
                    {mainHeader.map((column, column_key) => (
                      <th key={column_key}>{column.label}</th>
                    ))}
                  </tr>
                </thead>

                <colgroup>
                  {mainHeader.map((column, column_key) => (
                    <col key={column_key} className={column.width} />
                  ))}
                </colgroup>
                <tbody>
                  <tr>
                    <td>
                      {SelectInputFeild({
                        name: "buySell",
                        handleChange: handleChange,
                        handleBlur: handleBlur,
                        value: values.buySell,
                        touched,
                        errors,
                        options: [
                          { title: "Buy", value: "Buy" },
                          { title: "Sell", value: "Sell" },
                        ]
                      })}
                    </td>
                    <td>
                      <div className="bootstrap-datepicker-wrapper">
                        <DatePicker
                          selected={values["tradeDate"]}
                          className={`form-control w-100 bg-light form-control-sm ${touched["tradeDate"] && !!errors["tradeDate"]
                            ? "is-invalid"
                            : ""
                            }`}
                          name="tradeDate"
                          onChange={(date) => setFieldValue("tradeDate", date)}
                          onBlur={handleBlur}
                          popperContainer={CalendarContainer}
                          popperPlacement="top-start"
                        />
                      </div>
                    </td>
                    <td>
                      <div className="bootstrap-datepicker-wrapper">
                        <DatePicker
                          selected={values["settleDate"]}
                          className={`form-control w-100 bg-light form-control-sm ${touched["settleDate"] && !!errors["settleDate"]
                            ? "is-invalid"
                            : ""
                            }`}
                          name="settleDate"
                          onChange={(date) => setFieldValue("settleDate", date)}
                          onBlur={handleBlur}
                          popperContainer={CalendarContainer}
                          popperPlacement="top-start"
                        />
                      </div>
                    </td>

                    <td className="w-td-80">
                      {TextInputFeild({
                        name: "ticker",
                        type: "text",
                        handleChange: handleChange,
                        value: values.ticker,
                        touched,
                        errors,
                      })}
                    </td>
                    <td
                      className="w-td-90 text-ellipsis"
                    >
                      {TextInputFeild({
                        name: "name",
                        type: "text",
                        handleChange: handleChange,
                        value: values.name,
                        touched,
                        errors,
                      })}
                    </td>
                    <td className="w-td-90">
                      {TextInputFeild({
                        name: "notional",
                        type: "number",
                        handleBlur: handleBlur,
                        handleChange: handleChange,
                        value: values.notional,
                        touched,
                        errors,
                      })}
                    </td>
                    <td className="w-td-60">
                      {TextInputFeild({
                        name: "price",
                        type: "number",
                        handleChange: handleChange,
                        value: values.price,
                        touched,
                        errors,
                      })}
                    </td>
                    <td className="w-td-60">USD</td>

                    <td>
                      {SelectInputFeild({
                        name: "riskGroup",
                        handleChange: handleChange,
                        handleBlur: handleBlur,
                        value: values.riskGroup,
                        touched,
                        errors,
                        options: [
                          { title: "R1", value: "R1" },
                          { title: "R2", value: "R2" },
                          { title: "R3", value: "R3" },

                        ],
                      })}
                    </td>

                    <td>
                      {TextInputFeild({
                        name: "comments",
                        type: "text",
                        handleChange: handleChange,
                        value: values.comments,
                        touched,
                        errors,
                      })}
                    </td>
                    <td className="w-td-80"> <span className="badge badge-pill badge-success mr-1"> Flagged </span></td>

                    <td className="tradeDate">
                      <div>

                        <Dropdown >
                          <Dropdown.Toggle variant="success" disabled={isSubmitting}>
                            {
                              isSubmitting ?
                                (<span className="visually-hidden mr-1">Loading...</span>)
                                : "Action"
                            }
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item onClick={e => { setFieldValue("saved"); submitForm() }}>Save</Dropdown.Item>
                            <Dropdown.Item onClick={e => { setFieldValue("submitted"); submitForm() }}>Submit</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <Button variant="danger" size="sm" onClick={deleteDataHandler}>Delete</Button>
                      </div>
                    </td>
                  </tr>
                  <tr className="sub-row">
                    <td colSpan="12">
                      <div className="innertable">
                        <table className="child-table">
                          <thead>
                            <tr>
                              {subHeader.map((column, column_key) => (
                                <th key={column_key}>{column.label}</th>
                              ))}
                            </tr>
                          </thead>
                          <colgroup>
                            {subHeader.map((column, column_key) => (
                              <col key={column_key} className={column.width} />
                            ))}
                          </colgroup>

                          <tbody>
                            <FieldArray
                              name="subRow"
                              render={(arrayHelpers) => {
                                const subRow = values.subRow;
                                return (
                                  <>
                                    {subRow && subRow.length > 0
                                      ? subRow.map((data, index) => (
                                        <tr key={index}>
                                          <td>
                                            <div className="d-flex align-items-center justify-content-center">
                                              <i
                                                onClick={() =>
                                                  arrayHelpers.push(initialValue.subRow[0])
                                                }
                                                className={`fa fa-plus-circle mr-2 ${index === 0 && "mr-4"
                                                  }`}
                                              />
                                              {index !== 0 && (
                                                <i
                                                  onClick={() => {
                                                    arrayHelpers.remove(
                                                      index
                                                    );
                                                    onRemoveChildItem(index);
                                                  }}
                                                  className="fa fa-minus-circle"
                                                />
                                              )}
                                            </div>
                                          </td>

                                          <td>
                                            {TextInputFeild({
                                              name: `subRow.${index}.allocation`,
                                              type: "number",
                                              handleChange: (e) => {
                                                setFieldValue(`subRow.${index}.allocation`, e.target.value);
                                                setFieldValue(`subRow.${index}.notional`, values.subRow[index][
                                                  "allocation"
                                                ] && values.notional ? percentage(e.target.value, values.notional) : 0, false);
                                              },
                                              value:
                                                values.subRow[index][
                                                "allocation"
                                                ],
                                              disabled: !values.notional,
                                              touched,
                                              errors,
                                              key: "allocation",
                                              index,
                                              isChild: true,
                                            })}
                                          </td>

                                          <td>
                                            {SelectInputFeild({
                                              name: `subRow.${index}.fund`,
                                              handleChange: handleChange,
                                              handleBlur: handleBlur,
                                              options: [
                                                { title: "F1", value: "F1" },
                                                { title: "F2", value: "F2" },
                                                { title: "F3", value: "F3" },

                                              ],
                                              errors,
                                              isChild: true,
                                              touched,
                                              index,
                                              key: "fund",
                                              value:
                                                values.subRow[index]["fund"],
                                            })}
                                          </td>

                                          <td>
                                            {SelectInputFeild({
                                              name: `subRow.${index}.strategy`,
                                              handleChange: handleChange,
                                              handleBlur: handleBlur,
                                              options: [
                                                { title: "S1", value: "S1" },
                                                { title: "S2", value: "S2" },
                                                { title: "S3", value: "S3" },

                                              ],
                                              isChild: true,
                                              touched,
                                              errors,
                                              index,
                                              key: "strategy",
                                              value:
                                                values.subRow[index][
                                                "strategy"
                                                ],
                                            })}
                                          </td>
                                          <td>
                                            {SelectInputFeild({
                                              name: `subRow.${index}.substrategy`,
                                              handleChange: handleChange,
                                              handleBlur: handleBlur,
                                              options: [
                                                { title: "S1S1", value: "S1S1" },
                                                { title: "S1S2", value: "S1S2" },
                                                { title: "S1S3", value: "S1S3" },
                                                { title: "S2S1", value: "S2S1" },
                                                { title: "S2S2", value: "S2S2" },
                                                { title: "S2S3", value: "S2S3" },
                                                { title: "S3S1", value: "S3S1" },
                                                { title: "S3S2", value: "S3S2" },
                                                { title: "S3S3", value: "S3S3" },

                                              ],
                                              isChild: true,
                                              errors,
                                              index,
                                              touched,
                                              key: "substrategy",
                                              value:
                                                values.subRow[index][
                                                "substrategy"
                                                ],
                                            })}
                                          </td>
                                          <td>
                                            {
                                              TextInputFeild({
                                                name: `subRow.${index}.notional`,
                                                type: "number",
                                                handleChange: handleChange,
                                                value: values.subRow[index][
                                                  "notional"
                                                ],
                                                touched,
                                                errors,
                                                key: "notional",
                                                index,
                                                disabled: true,
                                                isChild: true,
                                              })}

                                          </td>
                                          <td>{data.price}</td>
                                          <td>{data.ccy}</td>
                                          <td>{data.mv}</td>
                                          <td>{data.ccyBase}</td>
                                          <td>{data.mvBase}</td>
                                          <td>{data.fundPre}</td>
                                          <td>{data.fundPost}</td>
                                          <td>{data.estRisk}</td>
                                          <td>{data.estRiskBase}</td>
                                          <td>{data.cash}</td>
                                          <td>{data.cashBase}</td>
                                          <td>{data.casht1}</td>
                                          <td>{data.casht2}</td>
                                        </tr>
                                      ))
                                      : null}
                                  </>
                                );
                              }}
                            />
                          </tbody>
                        </table>
                        <table className="nested-table">
                          <colgroup>
                            {subHeader.map((column, column_key) => (
                              <col key={column_key} className={column.width} />
                            ))}
                          </colgroup>
                          <tbody>
                            {formValues.map(
                              (subAddeddata, subAddeddata_key) => (
                                <tr key={subAddeddata_key}>
                                  {Object.keys(subAddeddata).map((data, i) => (
                                    <td key={i}>{subAddeddata[data]}</td>
                                  ))}
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </ReactForm>
          )}
        </Formik>
      </Card.Body>

    </Card>
  );
};
export default TableFormGenerator;
