import React, { useState } from "react";
import moment from "moment";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import "./Dashboard.scss";
import { useKeycloak } from '@react-keycloak/web';
import printJS from 'print-js'

const Header = (props) => {
  const {i ,keycloak } = useKeycloak();
  const [isActive, setActive] = useState(false);
  const [isHover, setHover] = useState(false);
  const { action } = props;

  const sidebar = (e, toggle) => {
    e.preventDefault();
    if (!isActive && isHover) {
      action(false);
      setHover(false);
    } else {
      setActive(toggle);
      action(toggle);
    }
  };

  const hoverSidebar = (e) => {
    e.preventDefault();
    if (!isActive) {
      action(true);
      setHover(true);
    }
  };

  const printDoc = () => {
    document.querySelector('body').style.overflow = 'auto'
    document.querySelector('body').style.height = 'auto'
    document.getElementById('root').style.height = 'auto'
    html2canvas(document.body, {
      onrendered: function(canvas)  
        {
          canvas.toBlob(function(blob) {
            const urlCreator = window.URL || window.webkitURL
            const imageUrl  = urlCreator.createObjectURL(blob)
            printJS({
              printable: imageUrl,
              type: 'image',
              header: `Hello User, Date: ${moment().format("MM/DD/YYYY hh:mm A")}`,
              headerStyle: 'font-weight: 300; font-size: 14px;'
            })
            document.body.scrollTop = 0
            document.documentElement.scrollTop = 0;
            document.querySelector('body').style.overflow = 'hidden'
            document.querySelector('body').style.height = '100vh'
            document.getElementById('root').style.height = '100vh'
          });
        }
      });
  }

  const getActivatedNav = (path) => {
    return (window.location.pathname === path || window.location.pathname.includes(path)) && "active-nav";
  };

  return (
    <>
      <Navbar className="header-color">
        <Navbar.Brand className="d-flex align-items-center" href="#home">
          <img
            alt=""
            src="http://dairfinance.digitalairtech.com/Image/DigitalAIr_logo_white.png"
            className="d-inline-block "
          />
          <span className="header-text">DAirFinance</span>
        </Navbar.Brand>
        <Nav className="mr-auto">
          <Nav.Link className="mt-1" onClick={(e) => sidebar(e, !isActive)}>
            <i className="fa fa-bars"></i>
          </Nav.Link>
        </Nav>
        <Nav className="menu-right">
          <Nav.Link onClick={printDoc}>Print</Nav.Link>
          <Nav.Link>{keycloak?.tokenParsed?.given_name}</Nav.Link>
          <Nav.Link eventKey={2} onClick={e => keycloak.logout() , localStorage.clear()}>
            <i className="fa fa-sign-out"></i>Log off
          </Nav.Link>
        </Nav>
      </Navbar>
      <Nav
        className={
          isActive || isHover
            ? "d-sm-none d-md-block d-lg-block d-block sidebar active header-color"
            : "d-sm-none d-md-block d-lg-block d-block sidebar header-color deactive"
        }
        // onMouseOver={e => sidebar(e, true)}
        onMouseOver={(e) => hoverSidebar(e)}
      >
        <Nav.Item>
          <Nav.Link
            as={Link}
            className={getActivatedNav("/dashboard")}
            to="/dashboard"
          >
            <i className="fa fa-dashboard" title="Dashboard">
              
            </i>
            <span className={isActive || isHover ? "show" : "hide"}>
              &nbsp; Dashboard
            </span>
          </Nav.Link>
        </Nav.Item>
        {/* <Nav.Item>
          <Nav.Link
            as={Link}
            className={getActivatedNav("/Portfolio")}
            to="/Portfolio"
          >
            <i className="fa fa-th" title="Portfolio">
            </i>
            <span className={isActive || isHover ? "show" : "hide"}>
              &nbsp; Portfolio
            </span>
          </Nav.Link>
        </Nav.Item> */}
        <Nav.Item>
          <Nav.Link
            as={Link}
            className={getActivatedNav("/security")}
            to="/security"
          >
            <i className="fa fa-shield" title="Security">
              
            </i>
            <span className={isActive || isHover ? "show" : "hide"}>
              &nbsp; Security
            </span>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            as={Link}
            className={getActivatedNav("/order/summary")}
            to="/order/summary"
          >
            <i className="fa fa-first-order" title="Order">
              
            </i>
            <span className={isActive || isHover ? "show" : "hide"}>
              &nbsp; Order
            </span>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
           as={Link}
           className={getActivatedNav("/trade")}
           to="/trade"
          >
            <i className="fa fa-line-chart" title="Trade">
              
            </i>
            <span className={isActive || isHover ? "show" : "hide"}>
              &nbsp; Trade
            </span>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link>
            <i className="fa fa-balance-scale" title="Risk Management">
              
            </i>
            <span className={isActive || isHover ? "show" : "hide"}>
              &nbsp; Risk Management
            </span>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item
          style={{ pointerEvents: "none" }}
          className={isActive || isHover ? "d-block" : "d-none"}
        >
          <Nav.Link>
            <li className="nav-header">OPERATION</li>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            as={Link}
            className={getActivatedNav("/fund-admin")}
            to="/fund-admin"
          >
            <i className="fa fa-user" title="Fund Admin">
            </i>
            <span className={isActive || isHover ? "show" : "hide"}>
              <span> &nbsp;&nbsp; Fund Admin</span>
            </span>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link>
            <i className="fa fa-bolt" title="OPS Console">
            </i>
            <span className={isActive || isHover ? "show" : "hide"}>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;OPS Console</span>
            </span>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item
          style={{ pointerEvents: "none" }}
          className={isActive || isHover ? "d-block" : "d-none"}
        >
          <Nav.Link>
            <li className="nav-header">ADMIN</li>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            as={Link}
            className={getActivatedNav("/profile")}
            to="/profile"
            >
            <i className="fa fa-user" title="Profiles">
              
            </i>
            <span className={isActive || isHover ? "show" : "hide"}>
              <span> &nbsp; Profiles</span>
            </span>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link>
            <i className="fa fa-users" title="HR">
              
            </i>
            <span className={isActive || isHover ? "show" : "hide"}>
              <span> &nbsp; HR</span>
            </span>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link>
            <i className="fa fa-square" title="Security">
              
            </i>
            <span className={isActive || isHover ? "show" : "hide"}>
              <span> &nbsp; Security</span>
            </span>
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </>
  );
};

export default Header;
