import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { Portal } from "react-overlays";

const MultiActionBtn = ({
  variant,
  onClick,
  size,
  label,
  options,
  className,
  type
}) => {
  const [btnlabel, setBtnLabel] = useState(label);
  return options && options.length > 0 ? (
    <Dropdown className={className}>
      <Dropdown.Toggle  variant={variant} size={size} title={btnlabel}>
        {btnlabel}
      </Dropdown.Toggle>

      <Portal>
      <Dropdown.Menu >
        {options.map((e, i) => (
          <Dropdown.Item
            key={i}
            onClick={() => {
              onClick(e);
              setBtnLabel(e);
            }}
          >
            {e}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
      </Portal>

    </Dropdown>
  ) : (
    <Button
      onClick={onClick}
      variant={variant}
      size={size}
      title={label}
      className={className}
      type={type}
    >
      {label}
    </Button>
  );
};

export default MultiActionBtn;
