import React, { useState, useEffect } from "react";
import "./Order.scss";
import { Button, Card, Table } from "react-bootstrap";
import styles from "./Blotter.module.scss";
import MultiActionBtn from "../../components/HigherOrderComponent/Button/MultiActionBtn"
import { useHistory } from "react-router-dom";



const tradeSummary = [];


const subData = [
  { name: "Alloc (%)" },
  { name: "Fund" },
  { name: "Strategy" },
  { name: "Sub-strategy" },
  { name: "Notional" },
  { name: "Price" },
  { name: "CCY" },
  { name: "MV" },
  { name: "CCY(Base)" },
  { name: "MV(Base)" },
  { name: "%Fund(pre)" },
  { name: "%Fund(post)" },
  { name: "Est Risk(%)" },
  { name: "Est Risk(Base)" },
  { name: "Cash%" },
  { name: "Cash(Base)" },
  { name: "Cash(t+1)" },
  { name: "Cash(t+2)" },
];
const Blotter = ({ height }) => {
  const [expandedOrderRows, setExpandedOrderRows] = useState([]);
  const [expandOrderState, setExpandOrderState] = useState({});

  const [expandedTradeRows, setExpandedTradeRows] = useState([]);
  const [expandTradeState, setExpandTradeState] = useState({});

  const [orderData, setOrderData] = useState([])
  const [tradeData, setTradeData] = useState(tradeSummary)
  const history = useHistory();



  useEffect(() => {
    let orders = localStorage.getItem("orders");
    orders = orders ? JSON.parse(orders) : [];
    setOrderData([...orders]);

  }, [])

  const deleteOrderDataHandler = (id) => {
    const findIndex = orderData.findIndex(
      (element) => element.id === Number(id)
    );

    if (findIndex != -1) {
      let newarray = orderData.slice()
      newarray.splice(findIndex, 1)
      setOrderData(newarray);
    }
  }

  const deleteTradeDataHandler = (id) => {

    const findIndex = tradeData.findIndex(
      (element) => element.id === Number(id)
    );

    if (findIndex != -1) {
      let newarray = tradeData.slice()
      newarray.splice(findIndex, 1)
      setTradeData(newarray);
    }
  }

  const handleEpandOrderRow = (event, userId) => {
    const currentExpandedRows = expandedOrderRows;
    const isRowExpanded = currentExpandedRows.includes(userId);

    let obj = {};
    isRowExpanded ? (obj[userId] = false) : (obj[userId] = true);
    setExpandOrderState(obj);

    const newExpandedRows = isRowExpanded
      ? currentExpandedRows.filter((id) => id !== userId)
      : currentExpandedRows.concat(userId);

    setExpandedOrderRows(newExpandedRows);
  };

  const handleEpandTradeRow = (event, userId) => {
    const currentExpandedRows = expandedTradeRows;
    const isRowExpanded = currentExpandedRows.includes(userId);

    let obj = {};
    isRowExpanded ? (obj[userId] = false) : (obj[userId] = true);
    setExpandTradeState(obj);

    const newExpandedRows = isRowExpanded
      ? currentExpandedRows.filter((id) => id !== userId)
      : currentExpandedRows.concat(userId);

    setExpandedTradeRows(newExpandedRows);
  };

  const navigateToTrade = (e) => {
    history.push({
      pathname: '/trade',
      search: `?id=${e}`
    });
    let pathUrl = window.location.href;
    window.location.href = pathUrl;
  }

  // config data

  const mainHeader = [
    {
      name: "Row", width: "w-td-80", cell: ({ order_id }) => (
        <Button
          variant="transparent"
          className={styles.expandBtn}
          onClick={(event) =>
            handleEpandOrderRow(event, order_id)
          }
        >
          {expandOrderState[order_id] ? (
            <i className="fa fa-minus-circle fa-xs"></i>
          ) : (
            <i className="fa fa-plus-circle fa-xs"></i>
          )}
        </Button>
      )
    },
    {
      name: "Order Id", width: "w-td-60", accessor: "order_id",
    },
    { name: "Instrument Type", width: "w-td-100", accessor: "instrumentType", default: "Equity" },
    {
      name: "Order Date", width: "w-td-80", accessor: "orderDate", default: new Date().toLocaleDateString()
    },
    { name: "Buy / Sell", width: "w-td-80", accessor: "buySell" },
    {
      name: "Trade Date", width: "w-td-80", accessor: "tradeDate",
      cell: ({ settleDate }) => new Date(settleDate).toLocaleDateString()
    },
    {
      name: "Settle Date", width: "w-td-80", accessor: "settleDate",
      cell: ({ settleDate }) => new Date(settleDate).toLocaleDateString()
    },
    { name: "Ticker", width: "w-td-80", accessor: "ticker" },
    { name: "Name", width: "w-td-90", accessor: "name" },
    { name: "Notional", width: "w-td-90", accessor: "notional" },
    { name: "Price", width: "w-td-60", accessor: "price" },
    { name: "CCY", width: "w-td-60", accessor: "ccy" },
    { name: "Risk Group", width: "w-td-90", accessor: "riskGroup" },
    { name: "Comments", width: "w-auto", accessor: "comments" },
    {
      name: "Status", width: "w-td-90", accessor: "status",
      cell: ({ status }) => (
        <span className="badge badge-pill badge-success mr-1">
          {status}
        </span>)
    },
    {
      name: "Action", width: "w-td-120", cell: () => (
        <>
          <MultiActionBtn className="mr-3" options={["#1234", "#2345", "#3456"]} variant="success" onClick={(e) => navigateToTrade(e)} size="sm" label="Save" />
          <MultiActionBtn className="mr-1" variant="danger" onClick={() => deleteOrderDataHandler(user.id)} size="sm" label="Delete" />
        </>
      )
    },
  ];

  const subHeader = [
    { name: "Alloc (%)", width: "tradeDate", accessor: "allocation" },
    { name: "Fund", width: "w-td-80", accessor: "fund" },
    { name: "Strategy", width: "w-td-80", accessor: "strategy" },
    { name: "Sub-strategy", width: "w-td-80", accessor: "substrategy" },
    { name: "Notional", width: "w-td-90", accessor: "notional" },
    { name: "Price", width: "w-td-60", accessor: "price" },
    { name: "CCY", width: "w-td-60", accessor: "ccy" },
    { name: "MV", width: "w-td-80", accessor: "mv" },
    { name: "CCY(Base)", width: "w-td-80", accessor: "ccyBase" },
    { name: "MV(Base)", width: "w-td-60", accessor: "mvBase" },
    { name: "%Fund(pre)", width: "w-td-80", accessor: "fundPre" },
    { name: "%Fund(post)", width: "w-td-80", accessor: "fundPost" },
    { name: "Est Risk(%)", width: "w-td-80", accessor: "estRisk" },
    { name: "Est Risk(Base)", width: "w-td-90", accessor: "estRiskBase" },
    { name: "Cash%", width: "w-td-60", accessor: "cash" },
    { name: "Cash(Base)", width: "w-td-80", accessor: "cashBase" },
    { name: "Cash(t+1)", width: "w-td-80", accessor: "casht1" },
    { name: "Cash(t+2)", width: "w-td-80", accessor: "casht2" },
  ];





  return (
    <>
      <div className={`${styles.orderSummary} order  panel panel-default mb-2`}>
        <div className="panel-body paneldecoration  h-100">
          <div className="row h-100">
            <div className="col-md-12 mb-2 d-flex align-items-center justify-content-between">
              <div className="table-title mb-0 ml-1"> Order Summary</div>

              <small className="mr-2">
                <span className="badge badge-pill badge-success mr-1">
                  Submitted : 2
                </span>
                <span className="badge badge-pill badge-info mr-1">
                  Saved : 4
                </span>
                <span className="badge badge-pill badge-warning mr-1">
                  Flagged : 2
                </span>
              </small>
            </div>
            <div className="col-md-12 h-100">
              <div className="table-responsive h-100 mb-3">
                <Card className={`${styles.cardBody} w-100`}>
                  <Card.Body className="w-100 card-body p-0">
                  <div className='table-responsive h-100' >
                    <table
                    className={`${styles.parentTable} w-100  table table-borderless table-striped text-white w-100 innertable-table parent-table  `}
                    >
                      <thead>
                        <tr>
                          {mainHeader.map((column, column_key) => (
                            <th key={column_key}>{column.name}</th>
                          ))}
                        </tr>
                      </thead>

                      <colgroup>
                        {mainHeader.map((column, column_key) => (
                          <col key={column_key} className={column.width} />
                        ))}
                      </colgroup>

                      <tbody>
                        {orderData.map((order, key) => (
                          <>
                            <tr key={key}>
                              {
                                mainHeader.map((ele, index) => {
                                  let data = ""
                                  if (ele.cell) {
                                    data = ele.cell(order);
                                  } else {
                                    data = order[ele.accessor] ?? (ele.default ?? "")
                                  }
                                  return (<td key={index}>{data}</td>)
                                }
                                )
                              }

                              {/* <td>
                                <Button
                                  variant="transparent"
                                  className={styles.expandBtn}
                                  onClick={(event) =>
                                    handleEpandOrderRow(event, order.order_id)
                                  }
                                >
                                  {expandOrderState[order.order_id] ? (
                                    <i className="fa fa-minus-circle fa-xs"></i>
                                  ) : (
                                    <i className="fa fa-plus-circle fa-xs"></i>
                                  )}
                                </Button>
                              </td>
                              <td>{order["order_id"]}</td>
                              <td>{order["instrument_type"] ?? "Equity"}</td>
                              <td>{order["orderDate"] ?? new Date().toLocaleDateString()}</td>
                              <td>{order["buySell"]}</td>
                              <td>{new Date(order["tradeDate"]).toLocaleDateString()}</td>
                              <td>{new Date(order["settleDate"]).toLocaleDateString()}</td>
                              <td>{order["ticker"]}</td>
                              <td>{order["name"]}</td>
                              <td>{order["notional"]}</td>
                              <td>{order["price"]}</td>
                              <td>{order["ccy"]}</td>
                              <td>{order["riskGroup"]}</td>

                              <td>{order["comments"]}</td>
                              <td></td>
                              <td>
                                <MultiActionBtn className="mr-3" options={["#1234", "#2345", "#3456"]} variant="success" onClick={(e) => navigateToTrade(e)} size="sm" label="Save" />
                                <MultiActionBtn className="mr-1" variant="danger" onClick={() => deleteOrderDataHandler(user.id)} size="sm" label="Delete" />
                              </td> */}
                            </tr>

                            <>
                              {expandedOrderRows.includes(order.order_id) ? (
                                <tr className="py-3 bg-light">
                                  <td colSpan="3"></td>
                                  <td
                                    className={`${styles.nestedTd}`}
                                    colSpan="11"
                                  >
                                    <div className="innertable">
                                      <table
                                        className={`${styles.nestedTable} child-table table table-borderless table-striped text-white w-100`}
                                      >
                                        <thead>
                                          <tr>
                                            {subHeader.map(
                                              (column, column_key) => (
                                                <th key={column_key}>
                                                  {column.name}
                                                </th>
                                              )
                                            )}
                                          </tr>
                                        </thead>


                                        <tbody>
                                          {order.subRow?.map((sub_data) => (
                                            <>
                                              <tr>
                                                {
                                                  subHeader.map((ele, index) => (
                                                    <td key={index}>{sub_data[ele?.accessor] ?? ""} </td>
                                                  ))
                                                }
                                              </tr>
                                            </>
                                          ))}

                                        </tbody>
                                      </table>
                                    </div>
                                  </td>
                                  <td colSpan="2"></td>

                                </tr>
                              ) : null}
                            </>
                          </>
                        ))}
                      </tbody>
                    </table>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="h-50 order panel panel-default" >
        <div className="panel-body paneldecoration h-100">
          <div className="row h-100 ">
            <div className="col-md-12 mb-2 d-flex align-items-center justify-content-between">
              <div className="table-title mb-0 ml-1"> Trade Summary</div>

              <small className="mr-2">
                <span className="badge badge-pill badge-warning mr-1">
                  Processing : 2
                </span>
                <span className="badge badge-pill badge-success mr-1">
                  Executed : 1
                </span>
                <span className="badge badge-pill badge-info mr-1">
                  settled : 2{" "}
                </span>
              </small>
            </div>
            
            <div className="col-md-12 h-100">
              <div className="table-responsive h-100 mb-3">
                <Card className={`${styles.cardBody} w-100`}>
                  <Card.Body className="w-100 card-body p-0">
                  <div className='table-responsive h-100' >
                    <table
                    className={`${styles.parentTable} w-100  table table-borderless table-striped text-white w-100 innertable-table parent-table  `}
                    >
                      
                      <thead>
                        <tr>
                          {mainHeader.map((column, column_key) => (
                            <th key={column_key}>{column.name}</th>
                          ))}
                        </tr>
                      </thead>

                      <colgroup>
                        {mainHeader.map((column, column_key) => (
                          <col key={column_key} className={column.width} />
                        ))}
                      </colgroup>

                          { tradeData.length ? 
                      <tbody>
                        {tradeData.map((user, i) => (
                          <>
                            <tr key={user.id}>
                              {Object.keys(user).map((data, i) => (
                                <td
                                  key={i}
                                  title={data === "name" ? user[data] : null}
                                  className={data === "name" ? "text-ellipsis" : null}
                                >
                                  {data === "id" ? (
                                    <Button
                                      variant="transparent"
                                      className={styles.expandBtn}
                                      onClick={(event) =>
                                        handleEpandTradeRow(event, user.id)
                                      }
                                    >
                                      {expandTradeState[user.id] ? (
                                        <i className="fa fa-minus-circle fa-xs"></i>
                                      ) : (
                                        <i className="fa fa-plus-circle fa-xs"></i>
                                      )}
                                    </Button>
                                  ) : (
                                    data === 'status' ? <span className="badge badge-pill badge-success mr-1">
                                      {user[data]}
                                    </span> : user[data]
                                  )}
                                </td>
                              ))}

                              <td>

                                <MultiActionBtn className="mr-3" options={["1", "2", "3"]} variant="success" onClick={() => console.log("")} size="sm" label="Save" />
                                <MultiActionBtn className="mr-1" variant="danger" onClick={() => deleteTradeDataHandler(user.id)} size="sm" label="Delete" />

                              </td>
                            </tr>
                            <>
                              {expandedTradeRows.includes(user.id) ? (
                                <tr>
                                  <td
                                    className={`${styles.nestedTd}`}
                                    colSpan="16"
                                  >
                                    <div className="innertable">
                                      <table
                                        className={`${styles.nestedTable} child-table table table-borderless table-striped text-white w-100`}
                                      >
                                        <thead>
                                          <tr>
                                            {subHeader.map(
                                              (column, column_key) => (
                                                <th key={column_key}>
                                                  {column.name}
                                                </th>
                                              )
                                            )}
                                          </tr>
                                        </thead>

                                        <colgroup>
                                          {subHeader.map(
                                            (column, column_key) => (
                                              <col
                                                key={column_key}
                                                className={column.width}
                                              />
                                            )
                                          )}
                                        </colgroup>

                                        <tbody>
                                          <tr>
                                            <td> </td>
                                            <td> </td>
                                            <td> </td>
                                            <td> </td>
                                            <td> </td>
                                            {subData.map((data, data_key) => (
                                              <td key={data_key}>
                                                {data.name}
                                              </td>
                                            ))}
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </td>
                                </tr>
                              ) : null}
                            </>
                          </>
                        ))}
                      </tbody> :<tbody><tr><td colSpan={16} className="pl-2 py-2">No data</td></tr></tbody> }
                    </table>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blotter;
