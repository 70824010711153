import React from 'react'
import { ToastContainer } from 'react-toastify'
import './Login.css'
import { useKeycloak } from '@react-keycloak/web';
import { Redirect } from 'react-router-dom';
import { Card, Button } from "react-bootstrap";
import IMAGE from"../../static/images/DigitalAIr_logo.png";
const Login = props => {
  const { keycloak } = useKeycloak();
  if (keycloak && keycloak.authenticated) {
    return <Redirect to="/dashboard" />;
  }
  return (
    <div className="login-wrapper">
      <ToastContainer />
      <Card className="text-light p-3 login-card">
        <Card.Body>
          <img className="mx-auto mb-4" src={IMAGE} alt="" width="auto" height="60" />
          <div className="d-grid gap-1">
            {keycloak && !keycloak.authenticated && (<Button variant="outline-light" size="lg" className="btn-block rounded-pill" onClick={e => { keycloak.login() , localStorage.clear()}}>SSO Login</Button>)
            }
          </div>
        </Card.Body>
      </Card>
    </div>
  );

}

export default Login
