import React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
export default function ProfileCard({ profile }) {
    return (
        <Card className="pb-0">
            <Card.Body className="profile-card pb-0">
                <Card.Text className="pt-3 px-3 ">
                    <Row>
                        <Col md={6} className="font-weight-bold pr-3 text-light">
                            <div><span>First Name: </span> {profile.firstName}</div>
                            <div><span>Last Name: </span> {profile.lastName}</div>
                            <div><span>Role: </span>{profile.role}</div>
                            <div><span>Region: </span>{profile.region}</div>
                        </Col>
                        <Col md={6}>
                            <div className="text-center">
                                <img src={profile.photo} alt="image" className="img-thumbnail profile-photo"   />
                            </div>
                        </Col>
                    </Row>
                </Card.Text>
                <Card.Text className="p-0">
                    <Row className="m-0 p-0">
                        <Col className="m-0 p-0"><Link to={`/profile/${profile.id}`}><Button variant="success" className="btn-block rounded-0">Edit</Button></Link></Col>
                        <Col className="m-0 p-0"><Link to={`/profile/${profile.id}?view=1`}><Button variant="primary" className="btn-block rounded-0">View</Button></Link></Col>
                    </Row>
                </Card.Text>
            </Card.Body>
        </Card>
    )
}
