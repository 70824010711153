import { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { equityTableData as tableData } from "../../../utilities/tablefields";
import Button from "react-bootstrap/Button";
import httpService from "../../../common/http.service";

import DataTable from '../../../components/HigherOrderComponent/DataTable/DataTable';
const EquityTable = (props) => {
  const { model } = props;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const viewModal = () => {
    props.viewTableModal("EquityTable");
  };


  useEffect(() => {
    setLoading(true);

    fetch("https://finance-demo-api.digitalairtech.net/getFlashBucket")
      .then(response => response.json())
      .then(data => {
        setData(data);
        setLoading(false);
      }).catch(_ => {
        setLoading(false);
        setData([]);
      });



  }, []);


  const config = {
    tableCard: {
      classes: ["h-inherit"]
    },
    tableWrapper: {
      classes: ["h-100"]
    },
  }

  return (
    <div className="card panel panel-default  my-2 h-100">
      <div className="card-body px-2 py-2 h-100">
        <div className="row">
          <div className="col-md-12">
            <div className="table-title">Equity</div>
            <div className="pull-right table-panel-header">
              <div className="lastupdate">Last Update :</div>
              <span className="lastupdateHead">00:00:00</span>
              {model && (
                <Button
                  onClick={() => viewModal()}
                  variant="default"
                  className="color-white button-padding"
                >
                  <i className="fa fa-window-maximize"></i>
                </Button>
              )}
            </div>
          </div>
        </div>
        <div className="row h-90">
          <div className="col-md-12 h-100">
            <DataTable
              pagination={true}
              columnData={tableData}
              config={config}
              loading={loading}
              itemPerPage={12}
              data={data} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EquityTable;
