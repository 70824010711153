
class AuthService {


  getToken () {
    return localStorage.getItem('token')
  }
  setToken(token){
    localStorage.setItem("token",token);
  }
  removeToken(){
    localStorage.removeItem("token",token);
  }
}

export default new AuthService()
