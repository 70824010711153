export function saveToLocal({values, collectionName, collectionId, selectedId }) {
    let temp = localStorage.getItem(collectionName);
    temp = temp ? JSON.parse(temp) : [];
    if (selectedId) {
        temp = temp.map(_ => {
            if (_[collectionId] == selectedId) {
                return { ...values };
            }
            return _;
        });
    } else {
        let id = {}
        id[collectionId] = temp.at(-1) ? temp.at(-1)[collectionId] + 1 : 1;
        temp.push({ ...id, ...values });
    }
    localStorage.setItem(collectionName, JSON.stringify(temp));
}


export function deleteFromLocal({collectionName, collectionId, selectedId}){
    let temp = localStorage.getItem(collectionName);
    temp = temp ? JSON.parse(temp) : [];
    temp = temp.filter(_ => _[collectionId] != selectedId);

    localStorage.setItem(collectionName, JSON.stringify(temp));
}

  export function  getLSHandler(moduleName)  {
    if (localStorage.getItem(moduleName)) {
      const module = JSON.parse(localStorage.getItem(moduleName));
      if (module) {
        return module.options;
      }
    } else {
      return null;
    }
  }

  export function setLSHandler(moduleName , options) {
    const module = JSON.stringify({
      options
    });
    localStorage.setItem(moduleName, module);
  }