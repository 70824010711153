import { useEffect, forwardRef, useRef } from 'react'

// eslint-disable-next-line react/display-name
const IndeterminateCheckbox = forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = useRef()
        const resolvedRef = ref || defaultRef

        useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <>
                <input type="checkbox" ref={resolvedRef} {...rest} />
            </>
        )
    }
)

export let checkBoxColumn = [{
    id: "selection",
    Header: () => <div />,
    width: 30,
    Cell: ({ row }) => (
        <div>
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
        </div>
    ),
}]
