import { createStore, applyMiddleware } from 'redux'
import { createLogger } from 'redux-logger'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddlerware from 'redux-saga';
import rootReducer from './ducks/rootReducer';
import { watcherSaga } from '../saga';
const sagaMiddleware = createSagaMiddlerware();
const loggerMiddleware = createLogger();

const middlewares = [sagaMiddleware,loggerMiddleware];



export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middlewares))
)

// after creating store and adding middleware
sagaMiddleware.run(watcherSaga);
