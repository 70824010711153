import { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap';
import FormGenerator from '../FormGenerator';
import { perform_report_tableData as tableData } from "../tableData";
import { DataTable, checkBoxColumn } from '../../../components/HigherOrderComponent/DataTable';
import * as yup from 'yup';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { ConfirmDialog } from '../../../components/Confirmation';
import { deleteFromLocal, saveToLocal } from '../../../common/localStorage.service';


export default function PerformanceReport() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            getRowData();
            setLoading(false)
        }, 3000)
    }, [])


    const getRowData = () => {
        let d = localStorage.getItem("alLPerformanceReports");
        setData(d ? JSON.parse(d) : []);
    }

    const deleteHandler = (cb) => {
        deleteFromLocal({ collectionName: "alLPerformanceReports", collectionId: "report_id", selectedId: selectedRow.report_id })
        setSelectedRow(null);
        getRowData();
        cb();
        return;
    }

    const onSubmit = (values, { setSubmitting, resetForm, setFieldError }) => {
        setSubmitting(true);
        setTimeout(() => {
            saveToLocal({ values,collectionName: "alLPerformanceReports", collectionId: "report_id", selectedId: selectedRow ? selectedRow.report_id : null });
            resetForm();
            setSubmitting(false);
            getRowData();
        }, 2000)
    }



    const onDelete = () => {
        if (selectedRow) {
            {
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return <ConfirmDialog onClose={onClose} onConfirm={deleteHandler} />
                    }
                });
            }
        }
    }

    const onRowSelect = ({ selectedFlatRows, selectedRowIds }) => {
        setSelectedRow(selectedFlatRows[0] ? selectedFlatRows[0].original : null);
    }

    const fundsFormData = [
        {
            label: "Fund Name:",
            type: "text",
            label_col: 5,
            input_col: 7,
            name: "fund_name",
            validations: yup.string().required("please enter fund name"),

        },
        {
            label: "Strategy Name:",
            type: "text",
            label_col: 5,
            input_col: 7,
            name: "strategy_name",
            validations: yup.string().required("please enter strategy name"),

        },
        {
            label: "Report Name:",
            type: "text",
            label_col: 5,
            input_col: 7,
            name: "report_name",
            validations: yup.string().required("please enter report name"),

        },
        {
            label: "File:",
            label_col: 5,
            input_col: 7,
            type: "file",
            name: "file",
            validations: yup.mixed().required("please select file"),

        },
    ];

    let schema = yup.object().shape({
        ...fundsFormData.reduce(
            (obj, item) => Object.assign(obj, { [item.name]: item.validations ?? "" }), {})
    });

    const config = {
        tableCard: {
            classes: ["h-100"]
        },
        tableWrapper: {
            classes: ["h-100"]
        }
    }
    return (
        <Row className="h-100 pb-4">
            <Col md={8} className="h-100" >
                <DataTable
                    pagination={true}
                    columnData={[...checkBoxColumn, ...tableData]}
                    data={data}
                    config={config}
                    loading={loading}
                    onRowSelect={onRowSelect}
                />
            </Col>
            <Col md={4}>
                <FormGenerator
                    formData={fundsFormData}
                    initialData={selectedRow}
                    onSubmit={onSubmit}
                    onDelete={onDelete}
                    schema={schema}
                    title={"Fund Details"}
                />
            </Col>
        </Row>
    )
}


