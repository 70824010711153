export const assignment_tableData = [
    {
        Header: "Employee ID",
        accessor: "employee_id",
        collapse:true,
        pinned: "",

        cellRenderer: "",
    },
    {
        Header: "Employee Name",
        accessor: "employee_name",
        collapse:true,
        pinned: "",

        cellRenderer: "",
    },
    {
        Header: "Employee Type",
        accessor: "employee_type",
        collapse:true,
        pinned: "",

        cellRenderer: "",
    },
    {
        Header: "Fund Name",
        accessor: "fund_name",
        collapse:true,
        pinned: "",

        cellRenderer: "",
    },
    {
        Header: "Strategy name",
        accessor: "strategy_name",
        collapse:true,
        pinned: "",

        cellRenderer: "",
    },
    {
        Header: "Sub-Strategy name",
        accessor: "sub_strategy_name",
        collapse:true,
        pinned: "",

        cellRenderer: "",
    },
    {
        Header: "Risk Group",
        accessor: "risk_group",
        collapse:true,
        pinned: "",

        cellRenderer: "",
    },
];
export const fund_tableData =    [
    
    {
        Header: "Fund ID",
        accessor: "fund_id",
        collapse:true,
    },
    {
        Header: "Fund Name",
        accessor: "fund_name",
        collapse:true,
    },
    {
        Header: "Investment Type",
        accessor: "investment_type",
        collapse:true,
    },
    {
        Header: "Leading PM",
        accessor: "leading_pm",
        collapse:true,
    },
    {
        Header: "Tax Code",
        accessor: "tax_code",
        collapse:true,
    },
    {
        Header: "Legal Structure",
        accessor: "legal_structure",
        collapse:true,
    },
    {
        Header: "Custodian",
        accessor: "custodian",
        collapse:true,
    },
    {
        Header: "Legal Representation",
        accessor: "legal_representation",
        collapse:true,
    },
    {
        Header: "Base Currency",
        accessor: "base_currency",
        collapse:true,
    },
    {
        Header: "Inception Date",
        accessor: "inception_date",
        collapse:true,
    },
    {
        Header: "Description",
        accessor: "description",
        collapse:true,
    },
]

export const group_tableData = [
    {
        Header: "Group ID",
        accessor: "group_id",
        collapse:true,
        width: 140,
        cellRenderer: "",
        flex: 1
    },
    {
        Header: "Group Name",
        accessor: "group_name",
        collapse:true,
        width: 140,
        cellRenderer: "",
        flex: 1
    },
    {
        Header: "Group type",
        accessor: "group_type",
        collapse:true,
        width: 140,
        cellRenderer: "",
        flex: 1
    },
    {
        Header: "Description",
        accessor: "description",
        collapse:true,
        width: 140,
        cellRenderer: "",
        flex: 1
    },
];

export const perform_report_tableData = [
    {
        Header: "Report ID",
        accessor: "report_id",
        collapse:true,
        pinned: "",
        width: "140",
        cellRenderer: "",
        flex: 1,
    },
    {
        Header: "Fund Name",
        accessor: "fund_name",
        collapse:true,
        pinned: "",
        cellRenderer: "",
        flex: 1,
    },
    {
        Header: "Strategy Name",
        accessor: "strategy_name",
        collapse:true,
        pinned: "",
        cellRenderer: "",
        flex: 1,
    },
    {
        Header: "Report Name",
        accessor: "report_name",
        collapse:true,
        pinned: "",
        cellRenderer: "",
        flex: 1,
    },
];

export const sub_strategy_tableData = [

    {
        Header: "Sub-Strategy Id",
        accessor: "sub_strategy_id",
        collapse:true,
        pinned: "",
        flex: 1,
        cellRenderer: "",
    },
    {
        Header: "Strategy Name",
        accessor: "strategy_name",
        collapse:true,
        pinned: "",
        flex: 1,
        cellRenderer: "",
    },
    {
        Header: "Sub-Strategy Name",
        accessor: "sub_strategy_name",
        collapse:true,
        pinned: "",
        flex: 1,
        cellRenderer: "",

    },
    {
        Header: "Description",
        accessor: "description",
        collapse:true,
        pinned: "",
        flex: 1,
        cellRenderer: "",
    },
];

export const strategy_tableData = [
    {
        Header: "Strategy Id",
        accessor: "strategy_id",
        collapse:true,
        pinned: "",
        cellRenderer: "",
    },
    {
        Header: "Strategy Name",
        accessor: "strategy_name",
        collapse:true,
        pinned: "",
        cellRenderer: "",
    },
    {
        Header: "Strategy type",
        accessor: "strategy_type",
        collapse:true,
        pinned: "",
        cellRenderer: "",
    },
    {
        Header: "Asset Class",
        accessor: "asset_class",
        collapse:true,
        pinned: "",
        cellRenderer: "",
    },
    {
        Header: "Instrument Type",
        accessor: "instrument_type",
        collapse:true,
        pinned: "",
        cellRenderer: "",
    }, {
        Header: "Description",
        accessor: "description",
        collapse:true,
        pinned: "",
        cellRenderer: "",
    },
]

