import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import BondTable from "./BondTable";
import EquityTable from "./EquityTable";
import FXTable from "./FXTable";
import '../../../components/Layout/Dashboard.scss'
const MarketSummary = () => {
  const [show, setShow] = useState(false);
  const [tableToView, setTableToView] = useState(null);
  const setModal = (e) => {
    setTableToView(e);
    setShow(true);
  };
  return (
    <>
      <div className=" row row-tables p-0 h-100">
        <div className="col-md-12 h-50">
          <BondTable model={true} viewTableModal={setModal} />
        </div>
        <div className="col-md-6 pr-0 h-50">
          <EquityTable className="equity" model={true} viewTableModal={setModal} />
        </div>
        <div className="col-md-6 pl-2 h-50">
          <FXTable model={true} viewTableModal={setModal} />
        </div>
      </div>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <div className="row">
            <div className="col-md-12">
              <h4 className="modal-title">
                {tableToView === "BondTable" && "Bonds"}
                {tableToView === "EquityTable" && "Equity"}
                {tableToView === "FXTable" && "FX"}
              </h4>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              {tableToView === "BondTable" && <BondTable id="bondTable" model={false} />}
              {tableToView === "EquityTable" && <EquityTable model={false} />}
              {tableToView === "FXTable" && <FXTable model={false} />}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MarketSummary;
