import React, { useEffect, useRef, useState } from "react";
import { createChart, PriceScaleMode, LineStyle } from "lightweight-charts";
import data from "../../../json/chart.json";

const LineChart = (props) => {
  let chartRef = useRef([])

  let areaSeriesOption = {
    topColor: "transparent",
    bottomColor: "transparent",
    lineColor: "rgba(240, 172, 78, 1)",
    lineWidth: 2,
    title: "AAPL",
  }

  let extraSeriesOption = {
    topColor: "transparent",
    bottomColor: "transparent",
    lineColor: "rgba(91, 192, 222, 1)",
    lineWidth: 2,
    title: "AAPL",
  }
  useEffect(() => {
      const chart =  createChart(chartRef.current, {
        width: chartRef.current.innerWidth,
        height: chartRef.current.innerHeight,
        ...props.chatOptions
      }
      );

      if(props.data.chartSet1 && props.data.chartSet1.length > 0){
        let areaSeries = chart.addAreaSeries(areaSeriesOption);
        areaSeries.setData(props.data.chartSet1);
      }
      
      if(props.data.chartSet2 && props.data.chartSet2.length > 0){
        let extraSeries = chart.addAreaSeries(extraSeriesOption);
        extraSeries.setData(props.data.chartSet2);
      }

      // resize observer (native JS)
      const ro = new ResizeObserver((entries) => {
        const cr = entries[0].contentRect;
        resize(cr.width, cr.height);
      });

      ro.observe(chartRef.current);

      if(chartRef.current.innerWidth){
        window.addEventListener("resize", () => {
          resize(chartRef.current.innerWidth, chartRef.current.innerHeight);
        });
      }

      const resize = (width, height) => {
        chart.resize(width, height);
      };
      chart.timeScale().fitContent();

  }, []);


  return (
    <div className="chart-container h-100">
    <div
      className="chartBox"
      ref={(el) => {
        chartRef.current = el;
      }}
    ></div>
  </div>
  );
};

export default LineChart;
  