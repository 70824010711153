import LineChart from '../../../components/HigherOrderComponent/Chart/LineCart'
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Badge from "react-bootstrap/Badge";
import React, {  useState } from "react";
import data from "../../../json/chart.json";
import { PriceScaleMode, LineStyle } from "lightweight-charts";

const SentimentSummary = () => {
    const labelData = [
        { primaryLabel: "VIX", secondaryLabel: "VOL" },
        { primaryLabel: "TY", secondaryLabel: "RATES" },
        { primaryLabel: "S&P 500", secondaryLabel: "EQUITY" },
        { primaryLabel: "DXY", secondaryLabel: "FX" },
        { primaryLabel: "WTI" },
        { primaryLabel: "COPPER" },
      ];
  const [show, setShow] = useState(false);

  const onBadgeClick = (i) =>{
    setShow(true)
  }
  const areaSeriesData = data.chartSet1;
  const extraSeriesData = data.chartSet2;


  let chatOptions = {
    rightPriceScale: {
      mode: PriceScaleMode.Normal,
      borderColor: "rgba(197, 203, 206, 0.4)",
    },
    timeScale: {
      borderColor: "rgba(197, 203, 206, 0.4)",
    },
    layout: {
      backgroundColor: "#485563",
      textColor: "#ffffff",
    },
    grid: {
      vertLines: {
        color: "rgba(0, 0, 0, 0)",
        style: LineStyle.Dotted,
      },
      horzLines: {
        color: "rgba(197, 203, 206, 0.4)",
        style: LineStyle.Dotted,
      },
    },
  }
    return (
        <>
        <div className="sentiment-summary row p-0">
          {labelData.map((e, i) => (
            <div className={`col-md-4 ${(i === 0 || i=== 1 || i=== 2) ? 'mb-3' : ''}  ${(i === 1 || i === 4) ? 'p-0' : ''}`} key={i}>
              <Card className="w-100 h-100">
                <Card.Body className="cardBody p-0">
                  <div className="badgeContainer d-flex align-items-center justify-content-between">
                    <Badge
                      onClick={() => onBadgeClick(i)}
                      bg="primary"
                      className="primaryLabel"
                    >
                      {e.primaryLabel}
                    </Badge>
                    <Badge bg="secondary" className="secondaryLabel">
                      {e.secondaryLabel}
                    </Badge>
                  </div>
                  <div>
                  <LineChart  data={ {chartSet1:areaSeriesData , chartSet2:extraSeriesData} } chatOptions={chatOptions} />
                  </div>
                  <div className=" labelContainer  d-flex align-items-center justify-content-center">
                    <div className="d-flex align-items-center mr-3">
                      <div className="label-line-actual"></div>
                      <span> Actual</span>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="label-line-sentiment"></div>
                      <span> Sentiment</span>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
           ))} 
        </div>

      {show && (
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          show={show}
          onHide={() => {
            setShow(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Sentiment Chart
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div className="row">
            <div className="col-md-12">
            <LineChart   data={ {chartSet1:areaSeriesData , chartSet2:extraSeriesData} }  chatOptions={chatOptions}/>
            </div>
          </div>
          </Modal.Body>
        </Modal>
      )}
    </>
    )
}

export default SentimentSummary