import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Card, Spinner } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import "./FormGenerator.scss";
import "react-datepicker/dist/react-datepicker.css";
import { Formik } from 'formik';

const inputFactor = (config) => {
    switch (config.formData.type) {
        case "date":
            return dateInputFeild(config);
        case "text":
            return TextInputFeild(config);
        case "text-area":
            return TextAreaInputFeild(config);
        case "select":
            return SelectInputFeild(config);
        case "file":
            return FileInputFeild(config);
        default:
            return TextInputFeild(config);

    }
}

function dateInputFeild(config) {
    return (
        <div className="bootstrap-datepicker-wrapper">
            <DatePicker
                selected={
                    config.values[config.formData.name] ?
                        new Date(config.values[config.formData.name])
                        : null
                }
                dateFormat="dd/MM/yyyy"
                className="form-control w-100 bg-light form-control-sm "
                name={config.formData.name}
                onChange={date => config.setFieldValue(config.formData.name, date)}
                onBlur={config.handleBlur}
            />
            {
                (config.errors[config.formData.name] && config.touched[config.formData.name]) ?

                    (<div className="invalid-field">
                        {config.errors[config.formData.name]}
                    </div>) : null
            }
        </div>
    );


}

function TextInputFeild(config) {
    return (
        <>
            <Form.Control
                type="text"
                size="sm"
                name={config.formData.name}
                value={config.values[config.formData.name]}
                onChange={config.handleChange}
                onBlur={config.handleBlur}
                isInvalid={config.touched[config.formData.name] && !!config.errors[config.formData.name]}
            />
            <Form.Control.Feedback type="invalid">
                {config.errors[config.formData.name]}
            </Form.Control.Feedback>
        </>
    )
}
function SelectInputFeild(config) {
    return (
        <>
            <select
                className="form-control form-control-sm"
                name={config.formData.name}
                onChange={config.handleChange}
                onBlur={config.handleBlur}
                value={config.values[config.formData.name]}
            >
                {config.formData.options.map((option, index) => {
                    return (
                        <option
                            key={index}
                            value={option.value ?? option}
                        >
                            {option.title ?? option}
                        </option>
                    )
                })}
            </select>
            {
                (config.errors[config.formData.name] && config.touched[config.formData.name]) ?

                    (<div className="invalid-field">
                        {config.errors[config.formData.name]}
                    </div>) : null
            }
        </>
    )

}
function TextAreaInputFeild(config) {
    return (<>
        <Form.Control as="textarea" rows={3}
            size="sm"
            name={config.formData.name}
            value={config.values[config.formData.name]}
            onChange={config.handleChange}
            onBlur={config.handleBlur}
            isInvalid={config.touched[config.formData.name] && !!config.errors[config.formData.name]}
        />
        <Form.Control.Feedback type="invalid">
            {config.errors[config.formData.name]}
        </Form.Control.Feedback>
    </>
    )
}
function FileInputFeild(config) {
    return (
        <>
            <Form.Control
                type="file"
                size="sm"
                name={config.formData.name}
                value={config.values[config.formData.name]}
                onChange={config.handleChange}
                onBlur={config.handleBlur}
                isInvalid={config.touched[config.formData.name] && !!config.errors[config.formData.name]}
            />
            <Form.Control.Feedback type="invalid">
                {config.errors[config.formData.name]}
            </Form.Control.Feedback>
        </>
    );
}


const FormGenerator = ({ formData, onSubmit, schema, title, initialData, onDelete }) => {
    const getInitialData = () => {
        let temp = {}
        formData.forEach(_ => temp[_.name] = "");
        return temp
    }

    let [initialValues, setInitalValue] = useState(getInitialData());
    let [edit, setEdit] = useState(false);


    useEffect(() => {
        setEdit(!!initialData)
        setInitalValue(initialData ?? getInitialData());
    }, [initialData]);



    const deleteHandler = () => {
        onDelete?.();
    }

    return (

        <Formik
            enableReinitialize={true}
            validationSchema={schema}
            onSubmit={onSubmit}
            initialValues={{ ...initialValues }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                resetForm
            }) => (
                <Form noValidate className="needs-validation h-100" onSubmit={handleSubmit}>

                    <Card className="form-generator-card h-100">
                        <Card.Body className="px-3 py-4 h-100">
                            <Card.Title className="h6 font-bold">{title}</Card.Title>
                            <div id="formInputsWrapper">
                                {
                                    formData.map((formData, key) => {
                                        return (
                                            <Form.Group as={Row} key={key} className="py-0">
                                                <Form.Label column sm={formData.label_col ?? 5} className="text-right">
                                                    {formData.label} 
                                                </Form.Label>
                                                <Col sm={formData.input_col ?? 7}>
                                                    {
                                                        inputFactor({ formData, values, touched, errors, handleChange, handleBlur, setFieldValue })
                                                    }
                                                </Col>
                                            </Form.Group>
                                        )
                                    })
                                }
                            </div>

                        </Card.Body>
                        <Card.Footer>
                            <div className="text-right">
                                <Button className="mr-1" size="sm" variant="primary" type="submit">
                                    {
                                        isSubmitting ?
                                            (<>   <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                                <span className="visually-hidden">Loading...</span></>)
                                            : edit ? "Update" : "Add new"
                                    }
                                </Button>
                                {
                                    edit ? <Button className="mr-1" size="sm" variant="danger" onClick={deleteHandler}>Delete</Button> : null
                                }
                            </div>
                        </Card.Footer>
                    </Card>
                </Form>

            )}

        </Formik>

    )

}

export default FormGenerator