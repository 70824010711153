import React, { useMemo, useEffect } from 'react';
import { useTable, usePagination, useFlexLayout, useResizeColumns, useExpanded, useBlockLayout, useRowSelect } from 'react-table';
import { useSticky } from 'react-table-sticky';
import { Card, Spinner } from "react-bootstrap";
import DataTableHead from './DataTableHead';
import DataTableRow from './DataTableRow';

const DataTable = ({
    columnData,
    data,
    itemPerPage = 10,
    config = null,
    pagination,
    renderRowSubComponent,
    loading = false,
    onRowSelect
}) => {
    // const dataTableRef = useRef();
    const columns = useMemo(() => [...columnData], []);
    let {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        visibleColumns,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, expanded, selectedRowIds },
        selectedFlatRows,
    } = useTable({
        columns,
        data,
        initialState: { pageIndex: 0, autoResetPage: true, pageSize: itemPerPage },
        stateReducer: (newState, action) => {
            if (action.type === "toggleRowSelected") {
                newState.selectedRowIds = {
                    [action.id]: action.value
                }
            }

            return newState;
        },
    },
        useFlexLayout,
        useResizeColumns,
        useExpanded,
        usePagination,
        useRowSelect,
        useBlockLayout,
        useSticky
    );
    useEffect(() => {
        selectedRowIds && onRowSelect?.({ selectedRowIds, selectedFlatRows })
    }, [selectedRowIds])


    return (
        <Card className={`w-100 ${config && config?.tableCard?.classes?.join(" ")}`} >
            <Card.Body className="react-table-card w-100 p-0" >
                <div className={`table-responsive ${config && config?.tableWrapper?.classes?.join(" ")}`} >
                    <table className={`table table-borderless table-striped text-white w-100 ${config?.smallTable ? "table-sm" : ""}`} {...getTableProps()}>
                        <DataTableHead
                            headerGroups={headerGroups}
                            headerConfig={config?.headerConfig}
                        />
                        {!loading && page?.length > 0 ?
                            (<DataTableRow
                                rowConfig={config?.rowConfig}
                                page={page}
                                prepareRow={prepareRow}
                                getTableBodyProps={getTableBodyProps}
                                visibleColumns={visibleColumns}
                                renderRowSubComponent={renderRowSubComponent}
                            />)
                            :
                            !loading ? (<tbody><tr><td className="pl-2 py-2">No data</td></tr></tbody>) : null
                        }
                    </table>
                    {
                        loading && (
                            < div className="h-100 d-flex justify-content-center align-items-center">
                                <Spinner
                                    animation="border"
                                    size="md"
                                    variant="light"
                                />
                            </div>)
                    }
                </div>
            </Card.Body>
            {page?.length > 0 && pagination &&
                <Card.Footer className="react-table-footer">
                    <div className="row align-items-center justify-content-end">
                        <div className="col-12 d-flex align-items-center justify-content-end">
                            <span>
                                Page{' '}
                                <strong>
                                    {pageIndex + 1} of {pageOptions.length}
                                </strong>{' '}
                            </span>

                            <ul className="pagination">
                                <li className="page-item" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                    <button className="page-link bg-transparent text-light border-0">{'<<'}</button>

                                </li>
                                <li className="page-item" onClick={() => previousPage()} disabled={!canPreviousPage}>

                                    <button className="page-link bg-transparent text-light border-0">{'<'}</button>

                                </li>
                                <li className="page-item" onClick={() => nextPage()} disabled={!canNextPage}>

                                    <button className="page-link bg-transparent text-light border-0"> {'>'}</button>

                                </li>
                                <li className="page-item" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>

                                    <button className="page-link bg-transparent text-light border-0">{'>>'}</button>

                                </li>

                            </ul>
                        </div>

                    </div>

                </Card.Footer>
            }
        </Card >
    )
}

export default DataTable