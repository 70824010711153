import { ReactKeycloakProvider } from '@react-keycloak/web';
import Keycloak from 'keycloak-js';
import AuthService from "./services/auth";
export const keycloak = new Keycloak({
    realm: process.env.REACT_APP_KEYCLOAK_REALM,
    clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
    url: process.env.REACT_APP_KEYCLOAK_URL,
    resource: process.env.REACT_APP_KEYCLOAK_RESOURCE
});

const KeycloakProvider = ({ children }) => {

    const keycloakEventHandler = (event, error) => {
        event && console.log({ event });
        error && console.log({ error });
        console.log({ keycloak });
        if (!error) {
            switch (event) {
                case "onAuthSuccess":
                    AuthService.setToken(keycloak.token);
                    break;
                case "onTokenExpired":
                    keycloak.updateToken(600).then(() => {
                        AuthService.setToken(keycloak.token);
                    });
                    break;
                case "onInitError":
                    //todo
                    break;
                case "onAuthError":
                    // todo
                    break;
                case "onInitError":
                    //todo
                    break;
                case "onAuthRefreshSuccess":
                    AuthService.setToken(keycloak.token);
                    break;
                case "onAuthRefreshError":
                    // todo
                    break;
                case "onAuthLogout":
                    // todo
                    break;
                default:
                    return;
            }
        }
    }

    return (
        <ReactKeycloakProvider authClient={keycloak} onEvent={keycloakEventHandler}>
            {children}
        </ReactKeycloakProvider>
    )
}

export default KeycloakProvider
