import React, { useState } from 'react';
import { Row, Col ,Button } from 'react-bootstrap';
import ProfileCard from './ProfileCard';
import { Link } from 'react-router-dom';
import "./Profile.css"
export default function Profile() {
    // const [profiles ,setProfiles] = useState([]);
    const profiles = [
        { id:1, firstName: "Mike", lastName: "Watson", role: "Test", region: "Zurik", photo: "https://lorempixel.com/1200/1200/" },
        { id:1,firstName: "Alferd", lastName: "Roy", role: "Test", region: "Oslo", photo: "https://lorempixel.com/1200/1200/" },
        { id:1,firstName: "Jose", lastName: "Franko", role: "Test", region: "London", photo: "https://lorempixel.com/1200/1200/" },
        { id:1,firstName: "Alferd", lastName: "Roy", role: "Test", region: "Oslo", photo: "https://lorempixel.com/1200/1200/" },

    ];

    return (
        <div className="px-3 pt-3">
        
            <Link to="/profile/add">
                <Button  variant="secondary" className="rounded-0">
                    Add New
                </Button>
            </Link>
            <Row>
                {
                    profiles.map((profile, i) => {
                        return (
                            <Col md={4} key={i} className="mt-2 mb-4">
                                <ProfileCard profile={profile} />
                            </Col>
                        )
                    })
                }
            </Row>
        </div>
    )
}
