import { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import FormGenerator from '../FormGenerator';
import { fund_tableData as tableData } from '../tableData';
import { DataTable, checkBoxColumn } from '../../../components/HigherOrderComponent/DataTable';
import * as yup from 'yup';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { ConfirmDialog } from '../../../components/Confirmation';
import { deleteFromLocal, saveToLocal } from '../../../common/localStorage.service';

export default function Fund() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            getRowData();
            setLoading(false)
        }, 3000)
    }, [])

    const fundsFormData = [
        {
            label: "Fund Name:",
            label_col: 5,
            input_col: 7,
            type: "text",
            name: 'fund_name',
            validations: yup
                .string()
                .required("Please enter fund name")

        },
        {
            label: "Investment Type:",
            label_col: 5,
            input_col: 7,
            type: "text",
            name: 'investment_type',
            validations: yup.string().required("please enter Investment type"),
        },
        {
            label: "Leading PM:",
            label_col: 5,
            input_col: 7,
            type: "text",
            name: 'leading_pm',
            validations: yup.string().required("please enter Leading PM")
        },
        {
            label: "Tax Code:",
            label_col: 5,
            input_col: 7,
            type: "text",
            name: 'tax_code',
            validations: yup.string().required("please enter Tax Code")
        },
        {
            label: "Legal Structure:",
            label_col: 5,
            input_col: 7,
            type: "text",
            name: 'legal_structure',
            validations: yup.string().required("please enter legal structure")
        },
        {
            label: "Custodian:",
            label_col: 5,
            input_col: 7,
            type: "text",
            name: 'custodian',
            validations: yup.string().required("please enter Custodian")
        },
        {
            label: "Registration:",
            label_col: 5,
            input_col: 7,
            type: "text",
            name: 'registration',
            validations: yup.string().required("please enter registration")
        },
        {
            label: "Legal Representation:",
            label_col: 5,
            input_col: 7,
            type: "text",
            name: 'legal_representation',
            validations: yup.string().required("please enter legal representation")
        },
        {
            label: "Base Currency",
            label_col: 5,
            input_col: 7,
            type: "text",
            name: 'base_currency',
            validations: yup.string().required("please enter base currency")
        },
        {
            label: "Inception Date:",
            label_col: 5,
            input_col: 7,
            type: "date",
            name: 'inception_date',
            validations: yup.string().required("please select inception date")
        },
        {
            label: "Descriptions:",
            label_col: 5,
            input_col: 7,
            type: "text",
            name: 'description',
            validations: yup.string().required("please enter descriptions")
        },
        {
            label: "Fund Prospectus",
            label_col: 5,
            input_col: 7,
            type: "text",
            name: 'fund_prospectus',
            validations: yup.string().required("please enter fund prospectus")
        },
    ];

    const getRowData = () => {
        let d = localStorage.getItem("allFunds");
        setData(d ? JSON.parse(d) : []);
    }
    let schema = yup.object().shape({
        ...fundsFormData.reduce(
            (obj, item) => Object.assign(obj, { [item.name]: item.validations ?? "" }), {})
    });


    const config = {
        tableCard: {
            classes: ["h-100"]
        },
        tableWrapper: {
            classes: ["h-100"]
        }
    }
    const deleteHandler = (cb) => {
        deleteFromLocal({ collectionName: "allFunds", collectionId: "fund_id", selectedId: selectedRow.fund_id })
        setSelectedRow(null);
        getRowData();
        cb();
        return;
    }

    const onSubmit = (values, { setSubmitting, resetForm, setFieldError }) => {
        setSubmitting(true);
        setTimeout(() => {
            saveToLocal({ values,collectionName: "allFunds", collectionId: "fund_id", selectedId: selectedRow ? selectedRow.fund_id : null });
            resetForm();
            setSubmitting(false);
            getRowData();
        }, 2000)
    }

    const onDelete = () => {
        if (selectedRow) {
            {
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return <ConfirmDialog onClose={onClose} onConfirm={deleteHandler} />
                    }
                });
            }
        }
    }

    const onRowSelect = ({ selectedFlatRows, selectedRowIds }) => {
        setSelectedRow(selectedFlatRows[0] ? selectedFlatRows[0].original : null);
    }

    return (
        <Row className="h-100 pb-4">
            <Col md={8} className="h-100" >
                <DataTable
                    pagination={true}
                    columnData={[...checkBoxColumn, ...tableData]}
                    data={data}
                    config={config}
                    loading={loading}
                    onRowSelect={onRowSelect}
                />
            </Col>
            <Col md={4}>
                <FormGenerator
                    formData={fundsFormData}
                    initialData={selectedRow}
                    onSubmit={onSubmit}
                    onDelete={onDelete}
                    schema={schema}
                    title={"Fund Details"}
                />
            </Col>
        </Row>
    )
}


