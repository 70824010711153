import React  from "react";
import { Form } from "react-bootstrap";
import { SectorList, PortfolioList , EventList } from "../../../utilities/tablefields";
import TabContent from "../../../components/HigherOrderComponent/TabContent";
import "../Security.scss"
import CandleStickChart from "../../../components/HigherOrderComponent/Chart/CandleStickChart";
import {DataTable} from '../../../components/HigherOrderComponent/DataTable';
import { LineStyle } from "lightweight-charts";
import LineChart from '../../../components/HigherOrderComponent/Chart/LineCart';
import data from '../../../json/chart.json';

const FXDetails = () => {

  let chatOptions = {
    leftPriceScale: {
      scaleMargins: {
        top: 0.2,
        bottom: 0.2,
      },
      visible: true,
      borderVisible: false,
    },
    layout: {
      backgroundColor: "#546575",
      textColor: "#ffffff",
    },
    rightPriceScale: {
      visible: false,
    },
    timeScale: {
      borderVisible: false,
    },
    grid: {
      vertLines: {
        color: "rgba(0, 0, 0, 0)",
        style: LineStyle.Dotted,
      },
      horzLines: {
        color: "rgba(197, 203, 206, 0.4)",
        style: LineStyle.Dotted,
      },
    },
  };

  const DetailsPartTwo = [
    "Local LIBOR rate",
    "Policy Rate",
    "3M Deposit Rate",
    "3M Lending Rate",
    "1yr IRS",
    "1yr govt bond yid",
  ];
  const DetailsPartThree = [
    "Issuer",
    "President",
    "Website (central bank)",
    "Description",
  ];

  const sectorListData = [
    {
      sectory: "sectory",
      industry: "industry",
      name: "name",
      Issuer: "Issuer",
      ID: "ID",
      PX_Last: "PX_Last",
      Open: "Open",
      High: "High",
      Low: "Low",
      Close: "Close",
      PrevClose: "PrevClose",
      Volume: "Volume",
    },
  ];

  const dataSet = [
    {
      id: "12345",
      name: "Sector",
    },
    {
      id: "678910",
      name: "Custom",
    },
  ];
  
  const columns = [
    {
      Header: () => <div className="d-none" />,
      accessor: "name",
      Cell: ({ row }) => (
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? (
            <i className="fa fa-chevron-down fa-xs mr-2"></i>
          ) : (
            <i className="fa fa-chevron-right fa-xs mr-2"></i>
          )}
         {row.values.name}
        </span>
      ),
    },
  ];
 

  const subComponent = (row) => {
    return (
      <div className="row m-0">
      <div className="col-md-12 expanded-table p-0">
        <DataTable
          columnData={SectorList}
          data={sectorListData}
          pagination={false}
        />
      </div>
      </div>
    );
  };

  
  const List = () => {
    return (
      <div className="row row-tables">
        <div className="col-md-12">
          <div className="panel panel-default">
            <div className="panel-body paneldecoration">
              <div className="row security-list">
                <div className="col-md-12">
                <DataTable
            columnData={columns}
            data={dataSet}
            pagination={false}
            renderRowSubComponent={subComponent}
            />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const Portfolio = () => {
    return (
      <div className="row row-tables">
        <div className="col-md-12">
          <div className="panel panel-default">
            <div className="panel-body paneldecoration">
              <div className="row">
              <div className="col-md-12">
                <DataTable columnData={PortfolioList} data={[]} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const Event = () => {
    return (
      <div className="row row-tables">
        <div className="col-md-12">
          <div className="panel panel-default">
            <div className="panel-body paneldecoration">
              <div className="row">
              <div className="col-md-12">
                <DataTable columnData={EventList} data={[]} />
                </div> 
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
 
  const Analytics = () => {
    return (
      <div>
        Analytics Works !
      </div>
    );
  };

  const tabMenu = [
    {
      title: "List",
      component: <List />,
    },
    {
      title: "Portfolio",
      component: <Portfolio />,
    },
    {
      title: "Analytics",
      component: <Analytics />
    },
    {
      title: "Events",
      component: <Event/>
    },
  ];

  return (
    <div className="row m-1">
      <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12 pr-0">
        <div className="content-border h-100 view-details">
          <div className="col-md-12 content-dotted-border col-lg-12">
            <div className="row">
              <div className="col-md-4 col-lg-4 col-xs-3 col-sm-6 col-6 my-1">
                <Form.Label className="mb-0">INR/USD</Form.Label>
                <Form.Text className="text-muted">(ID/Ticker)</Form.Text>
              </div>
              <div className="col-md-4 col-lg-4 col-xs-3 col-sm-6 col-6 my-1">
                <Form.Label className="mb-0">PX_Last</Form.Label>
              </div>
              <div className="col-md-4 col-lg-4 col-xs-3 col-sm-6 col-6 my-1">
                <Form.Label className="mb-0">+/- vs ytdy</Form.Label>
              </div>
              <div className="col-md-4 col-lg-4 col-xs-3 col-sm-6 col-6 my-1">
                <Form.Label className="mb-0">Timestamp</Form.Label>
              </div>
              <div className="col-md-6 col-sm-12 col-12 my-1">
                <Form.Label className="mb-0">Indian Rupee</Form.Label>
                <Form.Text className="text-muted">(name)</Form.Text>
              </div>
              <div className="col-md-4 col-lg-4 col-xs-3 col-sm-6 col-6 my-1">
                <Form.Label className="mb-0">India</Form.Label>
                <Form.Text className="text-muted">(country)</Form.Text>
              </div>
              <div className="col-md-4 col-lg-4 col-xs-3 col-sm-6 col-6 my-1">
                <Form.Label className="mb-0">Asia</Form.Label>
                <Form.Text className="text-muted">(region)</Form.Text>
              </div>
              <div className="col-md-4 col-lg-4 col-xs-3 col-sm-6 col-6 my-1">
                <Form.Label className="mb-0">1,000,000,000</Form.Label>
                <Form.Text className="text-muted">(Outstanding)</Form.Text>

              </div>
              <div className="col-md-4 col-lg-4 col-xs-3 col-sm-6 col-6 my-1">
                <Form.Label className="mb-0">2000</Form.Label>
                <Form.Text className="text-muted">(min lot)</Form.Text>
              </div>
              <div className="col-md-4 col-lg-4 col-xs-3 col-sm-6 col-6 my-1"></div>
              <div className="col-md-4 col-lg-4 col-xs-3 col-sm-6 col-6 my-1"></div>
              
              <div className="col-md-4 col-lg-4 col-xs-3 col-sm-6 col-6 my-1">
                <Form.Label className="mb-0">PX_Range</Form.Label>
                <Form.Text className="text-muted">(today)</Form.Text>
              </div>
              <div className="col-md-4 col-lg-4 col-xs-3 col-sm-6 col-6 my-1">
                <Form.Label className="mb-0">PX_Range</Form.Label>
                <Form.Text className="text-muted">(MTD)</Form.Text>
              </div>
              <div className="col-md-4 col-lg-4 col-xs-3 col-sm-6 col-6 my-1">
                <Form.Label className="mb-0">PX_Range</Form.Label>
                <Form.Text className="text-muted">(YTD)</Form.Text>
              </div>
              <div className="col-md-4 col-lg-4 col-xs-3 col-sm-6 col-6 my-1">
                <Form.Label className="mb-0">Trading Vol</Form.Label>
                <Form.Text className="text-muted">(today)</Form.Text>
              </div>
            </div>
          </div>
          <div className="col-md-12 content-dotted-border col-lg-12">
            <div className="row">
              {DetailsPartTwo &&
                DetailsPartTwo.length &&
                DetailsPartTwo.map((item, index) => (
                  <div key={index} className="col-md-6 my-1">
                    <Form.Label>{item}</Form.Label>
                  </div>
                ))}
            </div>
          </div>

          <div className="col-md-12 col-lg-12">
            <div className="row">
              {DetailsPartThree &&
                DetailsPartThree.length &&
                DetailsPartThree.map((item, index) => (
                  <div key={index} className="col-md-6 my-1">
                    <Form.Label>{item}</Form.Label>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-8 col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="content-border h-100">
          <div className="row">
            <div className="col-12">
            <LineChart  data={ {chartSet1:data.equityChart  } }  chatOptions={chatOptions} />
            </div>
            <div className="col-12">
              <CandleStickChart />
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12 py-15">
        <TabContent tabMenu={tabMenu} />
      </div>
    </div>
  );
};

export default FXDetails;
