import { useState, useEffect, createContext } from "react";
import StompJs from "stompjs";
import { useKeycloak } from "@react-keycloak/web";
import * as SockJS from "sockjs-client";

export const WebSocketContext = createContext({
    ws: null,
    connected: false,
});

export const Provider = ({ children }) => {
    const [ws, setWs] = useState(null);
    const [connected, setConnected] = useState(false);
    const { keycloak, initialized } = useKeycloak();
    useEffect(() => {
        if (initialized && keycloak.authenticated) {
            let socket = new SockJS(
                `${process.env.REACT_APP_FIN_TECH_WEBSOCKET}?token=Bearer ${keycloak.token}&type=websocket`
            );
            socket.withCredentials = true;
            setWs(StompJs.over(socket));
        }
    }, [setWs, initialized, keycloak]);

    useEffect(() => {
        if (ws && !connected) {
            ws.connect(
                {},
                (frames) => {
                    console.log({ frames })
                    setConnected(true);
                },
                (error) => {
                    console.log({ error })
                    setConnected(false);
                }
            );
        }
    }, [ws, connected]);

    return (

        <WebSocketContext.Provider value={{ ws, connected }}>
            {children}
        </WebSocketContext.Provider>

    )
}

