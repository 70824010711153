import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { FXTableData as tableData } from "../../../utilities/tablefields";
import { DataTable } from "../../../components/HigherOrderComponent/DataTable";
import httpService from "../../../common/http.service";

const FXTable = (props) => {
  const { model } = props;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const viewModal = () => {
    props.viewTableModal("FXTable");
  };

  useEffect(() => {
    setLoading(true);
    fetch("https://finance-demo-api.digitalairtech.net/getCurrencyMap")
      .then(response => response.json())
      .then(res => {
        // setData(data.Data);
        let data =  Object.keys(res).map(key=>{
          return {
            "symbol":key,
            ...res[key]
          }
        })
        setData(data);
        setLoading(false);
      }).catch(_=>{
        setLoading(false);
        setData([]);
      })
   
    //   {
    //     "ticker": "EUR/USD",
    //     "change": 0.1855,
    //     "bid": 1.1338,
    //     "ask": 1.1343,
    //     "low": 1.1317,
    //     "high": 1.1356,
    //     "open": 1.1322,
    //     "prev": 1.1324,

    //   },
    //   {
    //     "ticker": "GBP/USD",
    //     "change": -0.1201,
    //     "bid": 1.3473,
    //     "ask": 1.3472,
    //     "low": 1.3466,
    //     "high": 1.3515,
    //     "open": 1.3493,
    //     "prev": 1.3492,

    //   },
    //   {
    //     "ticker": "JPY/USD",
    //     "change": -0.21,
    //     "bid": 0.0087,
    //     "ask": 0.0087,
    //     "low": 0.0087,
    //     "high": 0.0088,
    //     "open": 0.0088,
    //     "prev": 0.0088,

    //   },
    //   {
    //     "ticker": "CHF/USD",
    //     "change": 0.1486,
    //     "bid": 1.0786,
    //     "ask": 1.0785,
    //     "low": 1.0762,
    //     "high": 1.081,
    //     "open": 1.0779,
    //     "prev": 1.0776,

    //   },
    //   {
    //     "ticker": "AUD/USD",
    //     "change": -0.1927,
    //     "bid": 0.7252,
    //     "ask": 0.7252,
    //     "low": 0.7251,
    //     "high": 0.7294,
    //     "open": 0.7267,
    //     "prev": 0.7266,

    //   },
    //   {
    //     "ticker": "CAD/USD",
    //     "change": -0.2649,
    //     "bid": 0.791,
    //     "ask": 0.7906,
    //     "low": 0.7907,
    //     "high": 0.7944,
    //     "open": 0.7931,
    //     "prev": 0.7931,

    //   },
    //   {
    //     "ticker": "SGD/USD",
    //     "change": -0.25,
    //     "bid": 0.7358,
    //     "ask": 0.736,
    //     "low": 0.7353,
    //     "high": 0.738,
    //     "open": 0.7378,
    //     "prev": 0.7378
    //   },
    // ]
    // setData(data);
  }, []);

  const config = {
    tableCard: {
      classes: ["h-inherit"]
    },
    tableWrapper: {
      classes: ["h-100"]
    },

  }

  return (
    <>
      <div className="card panel panel-default my-2 h-100 ">
        <div className="card-body px-2 py-2 h-100">
          <div className="row">
            <div className="col-md-12">
              <div className="table-title">FX</div>
              <div className="pull-right table-panel-header">
                <div className="lastupdate">Last Update :</div>
                <span className="lastupdateHead">00:00:00</span>

                {model && (
                  <Button
                    onClick={() => viewModal()}
                    variant="default"
                    className="color-white button-padding"
                  >
                    <i className="fa fa-window-maximize"></i>
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div className="row h-90">
            <div className="col-md-12 h-100">
              <DataTable
                pagination={true}
                config={config}
                columnData={tableData}
                loading={loading}
                itemPerPage={9}
                data={data} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FXTable;
