import React, {useState} from 'react'


import { Nav } from "react-bootstrap";
export default function TabContent({tabMenu , padding}) {
    const [tabIndex, setTabIndex] = useState(0)

    return (
            <div className='row m-1 p-0'>
                <div className="col-lg-10 col-md-10 col-sm-12 p-0">
                    <Nav className='bond-menu' fill variant='tabs'>
                        {
                            tabMenu.map((_, index) => {
                                return (
                                    <Nav.Item  key={index}>
                                        <Nav.Link
                                            eventKey={`link-${index}`}
                                            className={tabIndex === index ? 'active' : ''}
                                            onClick={() => setTabIndex(index)}
                                        >
                                            {_.title}
                                        </Nav.Link>
                                    </Nav.Item>
                                )
                            })
                        }

                    </Nav>
                </div>
                <div className="dashboard-tab-content p-0">
                    <div className={`${padding ? padding : 'p-3'} tab-content tab-content-custom h-100`}>
                        {
                            tabMenu.map((_, index) => {
                                return(<div key={index} className={tabIndex === index ? 'show' : 'hide'}>
                                    {_.component}
                                </div>)
                            })
                        }

                    </div>
                </div>
            </div>
    )
}
