import { Form } from "react-bootstrap";
import CandleStickChart from '../../components/HigherOrderComponent/Chart/CandleStickChart';
import styles from './Trade.module.scss';
let data = [
  { label: "Symbol:", value: "ID / ISIN" },
  { label: "Full Name:", value: "CAP NAT" },
  { label: "Sector:", value: "ISSUE" },
  { label: "Outstanding Share:", value: "1000" },
];
const Snapshot = () => {
  return (
    <div className={`${styles.paneldecorationTab} panel panel-default h-100` }>
      <div className="panel-body paneldecoration content-dotted-border">
        <div className="row">
          {data.map((column, column_key) => (
            <div key={column_key} className="col-md-6 mb-2">
              <div className="row align-items-center m-1">
                <div className="col-md-6 font-weight-bold p-0">
                  <Form.Label className="mb-0">{column.label}</Form.Label>
                </div>
                <div className="col-md-6 p-0">
                  {column.value}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="panel-body paneldecoration h-100 content-dotted-border">
        <div className="row">
        <div className="col-12">
        <div className={`${styles.chartHeading} table-title mb-0 `}>Historical</div>
            </div>
        <div className="col-12">
              <CandleStickChart />
            </div>
            <div className="col-12">
        <div className={`${styles.chartHeading} table-title mb-0 `}>Live</div>
            </div>
            <div className="col-12">
              <CandleStickChart />
            </div>
            <div className="col-12">
        <div className={`${styles.chartHeading} table-title mb-0 `}>Analytics</div>
            </div>
            <div className="col-12">
              <CandleStickChart />
            </div>
        </div>
        </div>
    </div>
  );
};

export default Snapshot;
