import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web';

function PrivateRoute({ component: Component, ...rest }) {
  const { keycloak } = useKeycloak();
  return (
    <Route
      {...rest}
      render={props => {
        if (keycloak && !keycloak.authenticated) {
          // not logged in so redirect to login page with the return url
          return (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          )
        }

        // logged in so return component
        return <Component {...props} />
      }}
    />
  )
}

export default PrivateRoute 
