import React from 'react';
import { Modal, Button } from 'react-bootstrap'
export const ConfirmDialog = ({ onClose, onConfirm }) => {

        return (<Modal.Dialog
            centered
        >
            <Modal.Body>
                <h4>Confirm to delete</h4>
                <p>
                    This action cannot be undone. Are you sure you want to delete this record
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onClose}>Close</Button>
                <Button variant="danger" onClick={() => onConfirm(onClose)}> Yes</Button>
            </Modal.Footer>
        </Modal.Dialog>)

    }
