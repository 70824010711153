import React, { useEffect, useRef } from "react";
import { createChart, LineStyle , CrosshairMode } from "lightweight-charts";
import "./Chart.css";
import data from "../../../json/chart.json";
const CandleStickChart = () => {
  let chartRef = useRef([]);

  let chatOptions = {
    leftPriceScale: {
      scaleMargins: {
        top: 0.2,
        bottom: 0.2,
      },
      visible: true,
      borderVisible: false,
    },
    layout: {
      backgroundColor: "#546575",
      textColor: "#ffffff",
    },
    rightPriceScale: {
      visible: false,
    },
   
    grid: {
      vertLines: {
        color: "rgba(0, 0, 0, 0)",
        style: LineStyle.Dotted,
      },
      horzLines: {
        color: "rgba(197, 203, 206, 0.4)",
        style: LineStyle.Dotted,
      },
    },
    crosshair: {
		mode: CrosshairMode.Normal,
    },
    timeScale: {
		borderColor: 'rgba(197, 203, 206, 0.8)',
	},
  };

  let areaSeriesOptions = {
    upColor: 'rgba(255, 144, 0, 1)',
    downColor: '#5bc0de',
    borderDownColor: 'rgba(91, 192, 222, 1)',
    borderUpColor: 'rgba(255, 144, 0, 1)',
    wickDownColor: 'rgba(255, 144, 0, 1)',
    wickUpColor: 'rgba(255, 144, 0, 1)',
  };

  useEffect(() => {
    const chart = createChart(chartRef.current, {
      width: chartRef.current.innerWidth,
      height: chartRef.current.innerHeight,
      ...chatOptions,
    });

    let candleSeries = chart.addCandlestickSeries(areaSeriesOptions);
    candleSeries.setData(data.fxChart);

    // resize observer (native JS)
    const ro = new ResizeObserver((entries) => {
      const cr = entries[0].contentRect;
      resize(cr.width, cr.height);
    });

    ro.observe(chartRef.current);

    if (chartRef.current.innerWidth) {
      window.addEventListener("resize", () => {
        resize(chartRef.current.innerWidth, chartRef.current.innerHeight);
      });
    }

    const resize = (width, height) => {
      chart.resize(width, height);
    };

    chart.timeScale().fitContent();
  }, []);

  return (
    <>
      <div className="chart-container h-100">
        <div
          className="chartBox"
          ref={(el) => {
            chartRef.current = el;
          }}
        ></div>
      </div>
    </>
  );
};

export default CandleStickChart;
