import { useState, useEffect, useContext } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import DatePicker from "react-datepicker";
import Button from "react-bootstrap/Button";
import { bondsTableData } from "../../../utilities/tablefields";
import { DataTable } from "../../../components/HigherOrderComponent/DataTable";
import httpService from "../../../common/http.service";
import { WebSocketContext } from "../../../WebSocket";
const BondTable = (props) => {
  const [startDate, setStartDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { model } = props;
  const { ws, connected } = useContext(WebSocketContext);


  const viewModal = () => {
    props.viewTableModal("BondTable");
  };

  const config = {
    tableCard: {
      classes: ["h-inherit"]
    },
    tableWrapper: {
      classes: ["h-100"]
    }
  }

 

  useEffect(() => {
    setLoading(true)
    setTimeout(()=>{
      let data = [
        {
          "ISIN": "US01609WAV46",
          "name": "Alibaba, 4.2% 6dec2047, USD",
          "currency": "USD",
          "coupon": 4.2,
          "price": 129.3,
          "maturityDate": "06.12.2047"
        },
        {
          "ISIN": "XS1324911608",
          "name": "BAT International Finance PLC, 4% 23nov2055, GBP",
          "currency": "GBP",
          "coupon": 4,
          "price": 114.37,
          "maturityDate": "23.11.2055"
        },
        {
          "ISIN": "US73928RAB24",
          "name": "Power Finance Corporation, 3.95% 23apr2030, USD",
          "currency": "USD",
          "coupon": 3.95,
          "price": 99.625,
          "maturityDate": "23.04.2030"
        },
        {
          "ISIN": "XS1324911608",
          "name": "BAT International Finance PLC, 4% 23nov2055, GBP",
          "currency": "GBP",
          "coupon": 4,
          "price": 116.19,
          "maturityDate": "23.11.2055"
        },
        {
          "ISIN": "XS1324911608",
          "name": "BAT International Finance PLC, 4% 23nov2055, GBP",
          "currency": "GBP",
          "coupon": 4,
          "price": 114.35,
          "maturityDate": "23.11.2055"
        },
        {
          "ISIN": "US01609WAV46",
          "name": "Alibaba, 4.2% 6dec2047, USD",
          "currency": "USD",
          "coupon": 4.2,
          "price": 129.08,
          "maturityDate": "06.12.2047"
        },
        {
          "ISIN": "XS1324911608",
          "name": "BAT International Finance PLC, 4% 23nov2055, GBP",
          "currency": "GBP",
          "coupon": 4,
          "price": 114.35,
          "maturityDate": "23.11.2055"
        },
        {
          "ISIN": "US01609WAV46",
          "name": "Alibaba, 4.2% 6dec2047, USD",
          "currency": "USD",
          "coupon": 4.2,
          "price": 130.99,
          "maturityDate": "06.12.2047"
        },
        {
          "ISIN": "XS1324911608",
          "name": "BAT International Finance PLC, 4% 23nov2055, GBP",
          "currency": "GBP",
          "coupon": 4,
          "price": 116.35,
          "maturityDate": "23.11.2055"
        },
        {
          "ISIN": "US01609WAV46",
          "name": "Alibaba, 4.2% 6dec2047, USD",
          "currency": "USD",
          "coupon": 4.2,
          "price": 129.68,
          "maturityDate": "06.12.2047"
        },
        {
          "ISIN": "US01609WAV46",
          "name": "Alibaba, 4.2% 6dec2047, USD",
          "currency": "USD",
          "coupon": 4.2,
          "price": 130.199,
          "maturityDate": "06.12.2047"
        },
        {
          "ISIN": "US73928RAB24",
          "name": "Power Finance Corporation, 3.95% 23apr2030, USD",
          "currency": "USD",
          "coupon": 3.95,
          "price": 100.998,
          "maturityDate": "23.04.2030"
        },
        {
          "ISIN": "XS1324911608",
          "name": "BAT International Finance PLC, 4% 23nov2055, GBP",
          "currency": "GBP",
          "coupon": 4,
          "price": 116.155,
          "maturityDate": "23.11.2055"
        },
        {
          "ISIN": "US01609WAV46",
          "name": "Alibaba, 4.2% 6dec2047, USD",
          "currency": "USD",
          "coupon": 4.2,
          "price": 130.43,
          "maturityDate": "06.12.2047"
        },
        {
          "ISIN": "US01609WAV46",
          "name": "Alibaba, 4.2% 6dec2047, USD",
          "currency": "USD",
          "coupon": 4.2,
          "price": 128.63,
          "maturityDate": "06.12.2047"
        },
        {
          "ISIN": "XS1324911608",
          "name": "BAT International Finance PLC, 4% 23nov2055, GBP",
          "currency": "GBP",
          "coupon": 4,
          "price": 113.52,
          "maturityDate": "23.11.2055"
        },
        {
          "ISIN": "XS1324911608",
          "name": "BAT International Finance PLC, 4% 23nov2055, GBP",
          "currency": "GBP",
          "coupon": 4,
          "price": 115.04,
          "maturityDate": "23.11.2055"
        },
        {
          "ISIN": "XS1324911608",
          "name": "BAT International Finance PLC, 4% 23nov2055, GBP",
          "currency": "GBP",
          "coupon": 4,
          "price": 113.48,
          "maturityDate": "23.11.2055"
        },
        {
          "ISIN": "US01609WAV46",
          "name": "Alibaba, 4.2% 6dec2047, USD",
          "currency": "USD",
          "coupon": 4.2,
          "price": 128.19,
          "maturityDate": "06.12.2047"
        },
        {
          "ISIN": "XS1324911608",
          "name": "BAT International Finance PLC, 4% 23nov2055, GBP",
          "currency": "GBP",
          "coupon": 4,
          "price": 113.62,
          "maturityDate": "23.11.2055"
        },
        {
          "ISIN": "US01609WAV46",
          "name": "Alibaba, 4.2% 6dec2047, USD",
          "currency": "USD",
          "coupon": 4.2,
          "price": 129.37,
          "maturityDate": "06.12.2047"
        },
        {
          "ISIN": "XS1324911608",
          "name": "BAT International Finance PLC, 4% 23nov2055, GBP",
          "currency": "GBP",
          "coupon": 4,
          "price": 115.165,
          "maturityDate": "23.11.2055"
        },
        {
          "ISIN": "US01609WAV46",
          "name": "Alibaba, 4.2% 6dec2047, USD",
          "currency": "USD",
          "coupon": 4.2,
          "price": 128.91,
          "maturityDate": "06.12.2047"
        },
        {
          "ISIN": "US01609WAV46",
          "name": "Alibaba, 4.2% 6dec2047, USD",
          "currency": "USD",
          "coupon": 4.2,
          "price": 131.047,
          "maturityDate": "06.12.2047"
        },
        {
          "ISIN": "XS1324911608",
          "name": "BAT International Finance PLC, 4% 23nov2055, GBP",
          "currency": "GBP",
          "coupon": 4,
          "price": 115.075,
          "maturityDate": "23.11.2055"
        },
        {
          "ISIN": "US01609WAV46",
          "name": "Alibaba, 4.2% 6dec2047, USD",
          "currency": "USD",
          "coupon": 4.2,
          "price": 129.722,
          "maturityDate": "06.12.2047"
        },
        {
          "ISIN": "US01609WAV46",
          "name": "Alibaba, 4.2% 6dec2047, USD",
          "currency": "USD",
          "coupon": 4.2,
          "price": 129.965,
          "maturityDate": "06.12.2047"
        },
        {
          "ISIN": "US73928RAB24",
          "name": "Power Finance Corporation, 3.95% 23apr2030, USD",
          "currency": "USD",
          "coupon": 3.95,
          "price": 101.5725,
          "maturityDate": "23.04.2030"
        },
        {
          "ISIN": "US01609WAV46",
          "name": "Alibaba, 4.2% 6dec2047, USD",
          "currency": "USD",
          "coupon": 4.2,
          "price": 128.67,
          "maturityDate": "06.12.2047"
        },
        {
          "ISIN": "XS1324911608",
          "name": "BAT International Finance PLC, 4% 23nov2055, GBP",
          "currency": "GBP",
          "coupon": 4,
          "price": 113.19,
          "maturityDate": "23.11.2055"
        }
      ]
      setData(data);
      setLoading(false)
    },1000)
  }, [])

  return (
    <>
      <div className="panel card panel-default mb-2 h-100">
        <div className="card-body px-2 py-2 h-100">
          <div className="row ">
            <div className="col-md-12">
              <div className="table-title">Bonds</div>
              <div className="pull-right table-panel-header">
              
                <div className="lastupdate">Last Update :</div>
                <span className="lastupdateHead">00:00:00</span>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                />
                <Button
                  variant="default"
                  className="color-white button-padding"
                >
                  <i className="fa fa-money"></i>Price
                </Button>
                {model && (
                  <Button
                    onClick={() => viewModal()}
                    variant="default"
                    className="color-white button-padding"
                  >
                    <i className="fa fa-window-maximize"></i>
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div className="row bond-table h-90">
            <div className="col-md-12 h-100">
              <DataTable
                pagination={true}
                config={config}
                columnData={bondsTableData}
                data={data}
                itemPerPage={11}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BondTable;
